import {
  CUSTOMER_LOGIN,
  CUSTOMER_LOGIN_SUCCESS,
  CUSTOMER_LOGIN_FAIL,
  GET_CUSTOMER_INFO,
  GET_CUSTOMER_INFO_SUCCESS,
  GET_CUSTOMER_INFO_FAIL,
  CLEAR_AUTH_STATE,
  CUSTOMER_UPDATE,
  CUSTOMER_UPDATE_SUCCESS,
  CUSTOMER_UPDATE_FAIL,
} from '../types';

import * as authService from '../../service/auth';
import * as customerService from '../../service/customer';

export const getCurrentCustomerInfo = () => {
  return (dispatch) => {
    const onGetCustomerInfoSuccess = (response) =>
      dispatch({
        type: GET_CUSTOMER_INFO_SUCCESS,
        payload: response,
      });

    const onGetCustomerInfoError = (error) => dispatch({ type: GET_CUSTOMER_INFO_FAIL, payload: error });

    dispatch({
      type: GET_CUSTOMER_INFO,
    });

    return customerService.getCurrentCustomerInfo().then(onGetCustomerInfoSuccess).catch(onGetCustomerInfoError);
  };
};

export const login = (email, password) => {
  return (dispatch) => {
    const onLoginSuccess = ({ user }) =>
      dispatch({
        type: CUSTOMER_LOGIN_SUCCESS,
        payload: user,
      });

    const onLoginError = (error) => dispatch({ type: CUSTOMER_LOGIN_FAIL, payload: error });

    dispatch({
      type: CUSTOMER_LOGIN,
    });

    return authService.login(email, password).then(onLoginSuccess).catch(onLoginError);
  };
};

export const clearAuthState = () => {
  return (dispatch) => {
    authService.clearAuthTokens();

    dispatch({
      type: CLEAR_AUTH_STATE,
    });
  };
};

export const updateCustomerInfo = (payload) => {
  return (dispatch) => {
    const { userId, body } = payload || {};

    const onUpdateCustomerInfoSuccess = (response) =>
      dispatch({
        type: CUSTOMER_UPDATE_SUCCESS,
        payload: response,
      });

    const onUpdateCustomerInfoError = (error) => dispatch({ type: CUSTOMER_UPDATE_FAIL, payload: error });

    dispatch({
      type: CUSTOMER_UPDATE,
    });

    return customerService
      .updateCustomerData(userId, body)
      .then(onUpdateCustomerInfoSuccess)
      .catch(onUpdateCustomerInfoError);
  };
};
