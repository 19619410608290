import pkg from '../package.json';

export default {
  baseURI: process.env.REACT_APP_API_BASE_URI,
  devMode: process.env.NODE_ENV === 'development',
  isProdMode: process.env.NODE_ENV === 'production',
  app: {
    name: pkg.name,
    version: pkg.version,
  },
  stripePublishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
  googlePlaceApiKey: process.env.REACT_APP_GOOGLE_PLACE_API_KEY,
};
