import { GET_AREA_LIST_FAIL, GET_BOOST_INFO, GET_BOOST_INFO_SUCCESS } from '../types';

const initState = {
  boosts: [],
  taxRates: [],
  billingAccounts: [],
  error: null,
  isLoading: false,
};

export default (state = initState, action) => {
  switch (action.type) {
    case GET_BOOST_INFO: {
      return { ...state, isLoading: true };
    }

    case GET_BOOST_INFO_SUCCESS: {
      const { payload } = action;
      const { taxRate, billingAccounts, prices } = payload;

      return { ...state, isLoading: false, boosts: prices, taxRates: taxRate, billingAccounts, error: null };
    }

    case GET_AREA_LIST_FAIL: {
      const { payload } = action;

      return { ...state, isLoading: false, boosts: [], taxRates: [], billingAccounts: [], error: payload };
    }

    default:
      return state;
  }
};
