import http from '../../utils/http';
import * as endpoints from '../../constants/endpoints';
import { GET_SUBSCRIPTION_LIST, GET_SUBSCRIPTION_LIST_SUCCESS, GET_SUBSCRIPTION_LIST_FAIL } from '../types';

export const getSubscriptionList = () => {
  return function (dispatch) {
    dispatch({ type: GET_SUBSCRIPTION_LIST });

    return http
      .get(endpoints.SUBSCRIPTION)
      .then((res) => dispatch(saveSubscriptionList(res.data)))
      .catch((err) => dispatch(errorOnFetchSubscriptionList(err)));
  };
};

const saveSubscriptionList = (payload) => ({
  type: GET_SUBSCRIPTION_LIST_SUCCESS,
  payload,
});

const errorOnFetchSubscriptionList = (payload) => ({
  type: GET_SUBSCRIPTION_LIST_FAIL,
  payload,
});
