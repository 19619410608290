export const GET_LOCATION_LIST = 'GET_LOCATION_LIST';
export const GET_LOCATION_LIST_SUCCESS = 'GET_LOCATION_LIST_SUCCESS';
export const GET_LOCATION_LIST_FAIL = 'GET_LOCATION_LIST_FAIL';

export const GET_CATEGORY_LIST = 'GET_CATEGORY_LIST';
export const GET_CATEGORY_LIST_SUCCESS = 'GET_CATEGORY_LIST_SUCCESS';
export const GET_CATEGORY_LIST_FAIL = 'GET_CATEGORY_LIST_FAIL';

export const GET_AREA_LIST = 'GET_AREA_LIST';
export const GET_AREA_LIST_SUCCESS = 'GET_AREA_LIST_SUCCESS';
export const GET_AREA_LIST_FAIL = 'GET_AREA_LIST_FAIL';

export const GET_DEAL_LIST = 'GET_DEAL_LIST';
export const GET_DEAL_LIST_SUCCESS = 'GET_DEAL_LIST_SUCCESS';
export const GET_DEAL_LIST_FAIL = 'GET_DEAL_LIST_FAIL';

export const CLEAR_AUTH_STATE = 'CLEAR_AUTH_STATE';

export const CUSTOMER_LOGIN = 'CUSTOMER_LOGIN';
export const CUSTOMER_LOGIN_SUCCESS = 'CUSTOMER_LOGIN_SUCCESS';
export const CUSTOMER_LOGIN_FAIL = 'CUSTOMER_LOGIN_FAIL';

export const CUSTOMER_UPDATE = 'CUSTOMER_UPDATE';
export const CUSTOMER_UPDATE_SUCCESS = 'CUSTOMER_UPDATE_SUCCESS';
export const CUSTOMER_UPDATE_FAIL = 'CUSTOMER_UPDATE_FAIL';

export const GET_CUSTOMER_INFO = 'GET_CUSTOMER_INFO';
export const GET_CUSTOMER_INFO_SUCCESS = 'GET_CUSTOMER_INFO_SUCCESS';
export const GET_CUSTOMER_INFO_FAIL = 'GET_CUSTOMER_INFO_FAIL';

export const GET_BOOST_INFO = 'GET_BOOST_INFO';
export const GET_BOOST_INFO_SUCCESS = 'GET_BOOST_INFO_SUCCESS';
export const GET_BOOST_INFO_FAIL = 'GET_BOOST_INFO_FAIL';

export const GET_SUBSCRIPTION_LIST = 'GET_SUBSCRIPTION_LIST';
export const GET_SUBSCRIPTION_LIST_SUCCESS = 'GET_SUBSCRIPTION_LIST_SUCCESS';
export const GET_SUBSCRIPTION_LIST_FAIL = 'GET_SUBSCRIPTION_LIST_FAIL';
