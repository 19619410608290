import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { FaBullhorn } from 'react-icons/fa';

// import ClaimDealItem from './ClaimDealItem';
import TextInput from '../../../../components/TextInput/TextInput';

const ClaimDeal = (props) => {
  const { name, value, onChange = () => {}, className, errorMessage } = props;

  return (
    <div
      className={classnames('claim-offer', {
        [className]: className,
      })}
    >
      <div className="claim-offer__title">CLAIMING</div>
      <div className="claim-offer__info mb-3">Type a passphrase which will be used by users to claim deals?</div>
      <div className="d-flex justify-content-center">
        {/* <ClaimDealItem title="Phrase" Icon={FaBullhorn} checked onChange={() => {}} /> */}
        <TextInput
          className="mb-4 uppercase"
          label="Phrase:"
          placeholder="Type a phrase"
          name={name}
          Icon={FaBullhorn}
          value={value}
          onChange={onChange}
          errorMessage={errorMessage}
        />
        {/* <ClaimDealItem title="Barcode" Icon={FaBarcode} checked={false} disabled />
        <ClaimDealItem title="QR Code" Icon={FaQrcode} checked={false} disabled /> */}
      </div>
    </div>
  );
};

ClaimDeal.propTypes = {
  className: PropTypes.string,
  errorMessage: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default ClaimDeal;
