import React from 'react';

import { FaTimesCircle } from 'react-icons/fa';

const PaymentFailure = () => {
  return (
    <div className="payment-fail-wrapper d-flex flex-column align-items-center w-100">
      <FaTimesCircle size={120} className="color-secondary-red" />
      <div className="title mt-4">Payment Failed</div>
      <div className="mt-2">Something went wrong. Subscription failed</div>
    </div>
  );
};

export default PaymentFailure;
