import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import ROUTES from '../../../constants/routes';
import { CUSTOMER_LOGIN_SUCCESS } from 'redux/types';

import { handleError } from '../../../utils/errorHandler';
import { isFormValid } from '../../../utils/formValidation';
import { isEmpty as isObjectEmpty } from '../../../utils/object';

import Button from '../../../components/Button/Button';
import TextInput from '../../../components/TextInput/TextInput';

import WelcomeVector from '../../../assets/images/welcome.svg';

import hourlyCombinationMarkLogo from '../../../assets/images/hourly-logo-combination-mark.png';

const Login = (props) => {
  const initialFormData = {
    email: '',
    password: '',
  };

  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState({});
  const [formData, setFormData] = useState(initialFormData);
  useEffect(() => {
    if (isObjectEmpty(props.loginError)) return;

    // handleError(props.loginError);
    setErrorMessage({ password: props.loginError.message });
  }, [props.loginError]);

  const onTextInputChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });

    setErrorMessage({});
  };

  const loginCustomer = async ({ email, password }) => {
    const response = await props.login(email, password);

    if (response.type === CUSTOMER_LOGIN_SUCCESS) {
      history.replace(ROUTES.LOCATION);
    }
  };

  const handleLoginClick = async (e) => {
    e.preventDefault();
    const requiredFields = {
      email: 'Email',
      password: 'Password',
    };

    const { isValid, validationMessage } = isFormValid(formData, requiredFields);

    if (!isValid) {
      return setErrorMessage(validationMessage);
    }

    loginCustomer(formData);
  };

  return (
    <>
      <div className="min-h-full flex">
        <div className="bg-white flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <img className="h-12 w-auto" src={hourlyCombinationMarkLogo} alt="Hourly app" />
              <h2 className="mt-6 text-3xl font-extrabold text-gray-900">Sign in to your account</h2>
              <p className="mt-2 text-sm text-gray-600">
                Or{' '}
                <Link to={ROUTES.AUTH.SIGN_UP} className="font-medium text-indigo-600 hover:text-indigo-500">
                  start your 30-day free trial
                </Link>
              </p>
            </div>

            <div className="mt-8">
              {/* <div>
                <div>
                  <p className="text-sm font-medium text-gray-700">Sign in with</p>

                  <div className="mt-1 grid grid-cols-3 gap-3">
                    <div>
                      <a
                        href="#"
                        className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                      >
                        <span className="sr-only">Sign in with Facebook</span>
                        <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                          <path
                            fillRule="evenodd"
                            d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </a>
                    </div>

                    <div>
                      <a
                        href="#"
                        className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                      >
                        <span className="sr-only">Sign in with Twitter</span>
                        <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                          <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                        </svg>
                      </a>
                    </div>

                    <div>
                      <a
                        href="#"
                        className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                      >
                        <span className="sr-only">Sign in with GitHub</span>
                        <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                          <path
                            fillRule="evenodd"
                            d="M10 0C4.477 0 0 4.484 0 10.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0110 4.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.203 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.942.359.31.678.921.678 1.856 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0020 10.017C20 4.484 15.522 0 10 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="mt-6 relative">
                  <div className="absolute inset-0 flex items-center" aria-hidden="true">
                    <div className="w-full border-t border-gray-300" />
                  </div>
                  <div className="relative flex justify-center text-sm">
                    <span className="px-2 bg-white text-gray-500">Or continue with</span>
                  </div>
                </div>
              </div> */}

              <div className="mt-6">
                <form onSubmit={handleLoginClick} className="space-y-6">
                  <div>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                      Email address
                    </label>
                    <div className="mt-1">
                      <input
                        id="email"
                        name="email"
                        onChange={(event) => onTextInputChange('email', event.target.value)}
                        type="email"
                        autoComplete="email"
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                      {errorMessage.email && (
                        <p className="mt-2 text-sm text-red-600" id="email-error">
                          {errorMessage.email}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="space-y-1">
                    <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                      Password
                    </label>
                    <div className="mt-1">
                      <input
                        id="password"
                        name="password"
                        onChange={(event) => onTextInputChange('password', event.target.value)}
                        type="password"
                        autoComplete="current-password"
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                      {errorMessage.password && (
                        <p className="mt-2 text-sm text-red-600" id="email-error">
                          {errorMessage.password}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      {/* <input
                        id="remember-me"
                        name="remember-me"
                        type="checkbox"
                        className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                      />
                      <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                        Remember me
                      </label> */}
                    </div>

                    <div className="text-sm">
                      {/* <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                        Forgot your password?
                      </a> */}
                    </div>
                  </div>

                  <div>
                    <button
                      type="submit"
                      className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Sign in
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden lg:block relative w-0 flex-1">
          <img
            className="absolute inset-0 h-full w-full object-cover saturate-50 brightness-75"
            src="https://images.unsplash.com/photo-1600093463592-8e36ae95ef56?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2340&q=80"
            alt=""
          />
        </div>
      </div>
    </>
  );

  return (
    <div className="login-container">
      <div className="login-card">
        <div className="login-card__welcome-card">
          <img src={WelcomeVector} alt="welcome image" />
          <div className="login-card-text">
            <div className="login-card-text__title">WELCOME TO HOURLY</div>
            <div className="login-card-text__sub-title">A great deal, every hour</div>
          </div>
        </div>

        <div className="login-card__vertical-border" />

        <form onSubmit={handleLoginClick}>
          <div className="login-card__form login-form-container">
            <div className="login-form-container__title mb-2">MEMBER LOGIN</div>
            <div className="login-form-container__sub-title mb-3">
              Build offers with our team to drive customers to you, when you need them.
            </div>

            <div>
              <TextInput
                className="mb-3"
                placeholder="Email"
                name="email"
                onChange={onTextInputChange}
                errorMessage={errorMessage.email}
              />
              <TextInput
                className="mb-3"
                type="password"
                placeholder="Password"
                name="password"
                onChange={onTextInputChange}
                errorMessage={errorMessage.password}
              />

              <div className="d-flex justify-content-between flex-row-reverse mb-5">
                <div className="forgot-password-link">FORGOT YOUR PASSWORD</div>
              </div>

              <div className="d-flex justify-content-between">
                <Link className="text-decoration-none" to={ROUTES.AUTH.SIGN_UP}>
                  <Button
                    type="button"
                    title="SIGN UP"
                    className="button-sign-in"
                    // onClick={() => {
                    //   history.push(ROUTES.AUTH.SIGN_UP);
                    // }}
                  />
                </Link>
                <Button
                  isLoading={props.isSubmitting}
                  title="LOGIN"
                  className={props.isSubmitting ? 'button-login btn-disabled' : 'button-login'}
                  disabled={props.isSubmitting}
                  type="submit"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

Login.propTypes = {
  loginError: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  login: PropTypes.func.isRequired,
};

export default Login;
