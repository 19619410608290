import { connect } from 'react-redux';

import Component from './Billing';
import { getBoostsInfo } from '../../redux/actions/billing';
import { getLocationOptions } from 'redux/selectors/location';
import { getLocationList } from '../../redux/actions/location';
import { getBillingAccountOptions } from 'redux/selectors/billing';
import { getSubscriptionList } from '../../redux/actions/subscription';

const mapStateToProps = (state, props) => {
  return {
    boosts: state.billing.boosts,
    taxRates: state.billing.taxRates,
    locations: getLocationOptions(state),
    isBillingLoading: state.billing.isLoading,
    billingAccounts: getBillingAccountOptions(state),
    subscriptions: state.subscription.subscriptions,
  };
};

const mapDispatchToProps = {
  getBoostsInfo,
  getLocationList,
  getSubscriptionList,
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
