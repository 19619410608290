import { connect } from 'react-redux';

import Component from './EditProfile';

import { getCurrentCustomerInfo, updateCustomerInfo } from '../../redux/actions/auth';

const mapStateToProps = (state, props) => {
  const customer = state.auth?.customer;

  return {
    customer,
    isFetchingCustomerInfo: state.auth.isLoading,
    isUpdatingCustomer: state.auth.isUpdatingCustomer,
  };
};

const mapDispatchToProps = { getCurrentCustomerInfo, updateCustomerInfo };

export default connect(mapStateToProps, mapDispatchToProps)(Component);
