import React, { useState } from 'react';

import Toast from 'components/Toast';
import MyDealsForm from 'screens/MyDeals/components/MyDealsForm';

import { handleError } from 'utils/errorHandler';
import * as dealService from '../../service/deal';

const initialFormData = {
  title: '',
  days: [
    {
      label: '',
      value: '',
    },
  ],
  locationIds: [
    {
      label: '',
      value: '',
    },
  ],
  timeSlot: {
    startTime: {
      label: '',
      value: '',
    },
    endTime: {
      label: '',
      value: '',
    },
  },
  claimCodes: {
    passphase: '',
  },
  image: [],
};

const AddDeal = (props) => {
  const [errorMessage, setErrorMessage] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState(initialFormData);

  const onClear = () => {
    setFormData(initialFormData);
    setErrorMessage({});
  };

  const onSubmit = async () => {
    setIsSubmitting(true);

    try {
      await dealService.addDeal(formData);
      Toast.success({ title: 'OK', message: 'Deal added successfully!' });

      setFormData({ ...initialFormData });
    } catch (err) {
      handleError(err);
    }

    setIsSubmitting(false);
  };

  return (
    <MyDealsForm
      pageTitle="Add new Deal"
      isSubmitting={isSubmitting}
      formData={formData}
      errorMessage={errorMessage}
      onClear={onClear}
      onSubmit={onSubmit}
      setFormData={setFormData}
      setErrorMessage={setErrorMessage}
    />
  );
};

AddDeal.propTypes = {};

export default AddDeal;
