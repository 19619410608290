import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const TextInput = React.forwardRef((props, ref) => {
  const {
    label,
    placeholder = '',
    onChange = () => {},
    value,
    Icon,
    className,
    errorMessage,
    type = 'text',
    name,
    disabled = false,
  } = props;

  return (
    <div className="mt-4">
      <label className="block text-sm font-medium text-gray-700">{label}</label>
      <div className="mt-1">
        <input
          ref={ref}
          name={name}
          onChange={(event) => onChange(name, event.target.value)}
          type={type}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          className={classnames([
            'shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md placeholder:text-slate-300',
            className,
          ])}
        />
        {errorMessage && (
          <p className="mt-2 text-sm text-red-600" id="email-error">
            {errorMessage}
          </p>
        )}
      </div>
    </div>
  );

  return (
    <div
      className={classnames('text-input', {
        [className]: className,
      })}
    >
      {label && <div className="text-input__label">{label}</div>}
      <div
        className={classnames('text-input__input', {
          'text-input__input--no-icon': !Icon,
        })}
      >
        {Icon && <Icon className="text-input__input__icon" />}
        <input
          ref={ref}
          name={name}
          onChange={(event) => onChange(name, event.target.value)}
          type={type}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
        />
      </div>
      {errorMessage && <div className="text-input__info">{errorMessage}</div>}
    </div>
  );
});

TextInput.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  Icon: PropTypes.elementType,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
};

export default TextInput;
