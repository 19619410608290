import http from '../utils/http';

import { interpolate } from 'utils/string';
import * as endpoints from '../constants/endpoints';

export const getCurrentCustomerInfo = async () => {
  const { data } = await http.get(endpoints.FETCH_CURRENT_USER);

  return data;
};

/**
 * Update Customer.
 *
 * @param {string} userId
 * @param {object} body
 *
 * @returns {object}
 */
export async function updateCustomerData(userId, body) {
  const formattedCustomerData = formatCustomerDataForAPI(body);

  const url = interpolate(endpoints.UPDATE_USER, { userId });
  const { data } = await http.patch(url, {
    body: formattedCustomerData,
    headers: { 'content-type': 'multipart/form-data' },
  });

  return data;
}

export const formatCustomerDataForAPI = (data) => {
  const { fullName, businessName, email, phone, image } = data;
  const file = image?.[0]?.file;

  const formattedData = {
    fullName,
    businessName,
    contacts: {
      email,
      phone,
    },
  };

  if (!file) {
    formattedData.imageUrl = image?.[0]?.data_url;
  }

  if (formattedData?.image) {
    delete formattedData.image;
  }

  const form = new FormData();
  const formData = JSON.stringify(formattedData);

  form.append('file', file);
  form.append('formData', formData);

  return form;
};
