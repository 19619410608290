import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import Toast from '../../components/Toast';
import Loader from '../../components/Loader/Loader';
import Button from '../../components/Button/Button';
import TextInput from '../../components/TextInput/TextInput';
import PageTitle from '../../components/PageTitle/PageTitle';
import UserImageUpload from './components/UserImageUpload';

import { handleError } from 'utils/errorHandler';
import { isFormValid } from '../../utils/formValidation';

import regex from 'constants/regex';

const isValidPhoneNumber = (phone) => {
  if (!regex.phoneNumber.test(phone)) return false;
  return true;
};

const initialFormData = {
  fullName: '',
  businessName: '',
  email: '',
  phone: '',
  image: [],
};

const EditProfile = (props) => {
  const {
    customer = {},
    isUpdatingCustomer,
    isFetchingCustomerInfo,
    updateCustomerInfo,
    getCurrentCustomerInfo,
  } = props;

  const [formData, setFormData] = useState(initialFormData);
  const [errorMessage, setErrorMessage] = useState({});

  useEffect(async () => {
    getCurrentCustomerInfo();
  }, [getCurrentCustomerInfo]);

  useEffect(() => {
    const image = [];

    if (customer?.imageUrl) {
      const imageData = {
        data_url: customer?.imageUrl,
        file: null,
      };

      image.push(imageData);
    }

    const formData = {
      fullName: customer?.fullName,
      businessName: customer?.businessName,
      email: customer?.contacts?.email,
      phone: customer?.contacts?.phone,
      image,
    };

    setFormData(formData);
  }, [customer, setFormData]);

  const onTextInputChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === 'phone' && !isValidPhoneNumber(value)) {
      setErrorMessage({
        ...errorMessage,
        [name]: 'Invalid Phone Number',
      });

      return;
    }

    setErrorMessage({});
  };

  const isFormValidated = () => {
    const requiredFields = {
      fullName: 'Full Name',
      businessName: 'Business Name',
      email: 'Email',
      phone: 'Phone',
    };

    let { isValid, validationMessage } = isFormValid(formData, requiredFields);

    // if (!formData?.image?.[0]?.data_url) {
    //   isValid = false;

    //   validationMessage = {
    //     ...validationMessage,
    //     image: 'Image is required',
    //   };
    // }

    if (!isValid) {
      setErrorMessage(validationMessage);
    }

    return isValid;
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    if (!isFormValidated()) {
      console.log('-invalid');
      return;
    }

    try {
      await updateCustomerInfo({
        userId: customer?._id,
        body: formData,
      });

      Toast.success({ title: 'OK', message: 'Profile Updated successfully!' });
    } catch (err) {
      handleError(err);
    }
  };

  if (isFetchingCustomerInfo || isUpdatingCustomer) {
    return <Loader />;
  }

  return (
    <div className="flex-1 xl:overflow-y-auto">
      <div className="max-w-3xl mx-auto py-10 px-4 sm:px-6 lg:py-12 lg:px-8">
        <h1 className="text-3xl font-extrabold text-blue-gray-900">Your Profile</h1>

        <form onSubmit={onSubmit} className="mt-6 space-y-8 divide-y divide-y-blue-gray-200">
          <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6">
            <div className="sm:col-span-6">
              {/* <h2 className="text-xl font-medium text-blue-gray-900">Your Profile</h2> */}
              <p className="mt-1 text-sm text-blue-gray-500">
                Please provide your contact details to help us contact you.
              </p>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="fullName" className="block text-sm font-medium text-blue-gray-900">
                Full name
              </label>
              <input
                type="text"
                name="fullName"
                value={formData.fullName}
                onChange={(event) => onTextInputChange('fullName', event.target.value)}
                id="fullName"
                autoComplete="given-name"
                className="mt-1 block w-full border-blue-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500"
              />
              {errorMessage.fullName && (
                <p className="mt-2 text-sm text-red-600" id="email-error">
                  {errorMessage.fullName}
                </p>
              )}
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="businessName" className="block text-sm font-medium text-blue-gray-900">
                Company name
              </label>
              <input
                type="text"
                name="businessName"
                value={formData.businessName}
                onChange={(event) => onTextInputChange('businessName', event.target.value)}
                id="businessName"
                autoComplete="family-name"
                className="mt-1 block w-full border-blue-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500"
              />
              {errorMessage.businessName && (
                <p className="mt-2 text-sm text-red-600" id="email-error">
                  {errorMessage.businessName}
                </p>
              )}
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="email" className="block text-sm font-medium text-blue-gray-900">
                Email address
              </label>
              <input
                type="text"
                name="email"
                id="email"
                value={formData.email}
                onChange={(event) => onTextInputChange('email', event.target.value)}
                autoComplete="email"
                className="mt-1 block w-full border-blue-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500"
              />
              {errorMessage.email && (
                <p className="mt-2 text-sm text-red-600" id="email-error">
                  {errorMessage.email}
                </p>
              )}
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="phone" className="block text-sm font-medium text-blue-gray-900">
                Phone number
              </label>
              <input
                type="text"
                name="phone"
                value={formData.phone}
                onChange={(event) => onTextInputChange('phone', event.target.value)}
                id="phone"
                autoComplete="tel"
                className="mt-1 block w-full border-blue-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500"
              />
              {errorMessage.phone && (
                <p className="mt-2 text-sm text-red-600" id="email-error">
                  {errorMessage.phone}
                </p>
              )}
            </div>

            {/* <div className="sm:col-span-6">
              <label htmlFor="photo" className="block text-sm font-medium text-blue-gray-900">
                Photo
              </label>
              <div className="mt-1 flex items-center">
                <img
                  className="inline-block h-12 w-12 rounded-full"
                  src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2.5&w=256&h=256&q=80"
                  alt=""
                />
                <div className="ml-4 flex">
                  <div className="relative bg-white py-2 px-3 border border-blue-gray-300 rounded-md shadow-sm flex items-center cursor-pointer hover:bg-blue-gray-50 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-blue-gray-50 focus-within:ring-blue-500">
                    <label
                      htmlFor="user-photo"
                      className="relative text-sm font-medium text-blue-gray-900 pointer-events-none"
                    >
                      <span>Change</span>
                      <span className="sr-only"> user photo</span>
                    </label>
                    <input
                      id="user-photo"
                      name="user-photo"
                      type="file"
                      className="absolute inset-0 w-full h-full opacity-0 cursor-pointer border-gray-300 rounded-md"
                    />
                  </div>
                  <button
                    type="button"
                    className="ml-3 bg-transparent py-2 px-3 border border-transparent rounded-md text-sm font-medium text-blue-gray-900 hover:text-blue-gray-700 focus:outline-none focus:border-blue-gray-300 focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue-gray-50 focus:ring-blue-500"
                  >
                    Remove
                  </button>
                </div>
              </div>
            </div> */}
          </div>

          <div className="pt-8 flex justify-end">
            <button
              type="button"
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-blue-gray-900 hover:bg-blue-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Cancel
            </button>
            <button
              type="submit"
              onClick={onSubmit}
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Update profile
            </button>
          </div>
        </form>
      </div>
    </div>
  );

  return (
    <main className="w-100">
      <header className="d-flex justify-content-between">
        <PageTitle title="Edit Profile" />
      </header>

      <UserImageUpload
        image={formData?.image}
        errorMessage={errorMessage.image}
        onImageChange={(file) => onTextInputChange('image', file)}
      />
      <section className="mt-5">
        <h3 className="fs-16px fw-700 color-greyish-brown">Account Information</h3>

        <div className="row">
          <TextInput
            className="col-6"
            label="Full Name"
            name="fullName"
            value={formData.fullName}
            onChange={onTextInputChange}
            errorMessage={errorMessage.fullName}
          />

          <TextInput
            className="col-6 mb-4"
            label="Company Name"
            name="businessName"
            value={formData.businessName}
            onChange={onTextInputChange}
            errorMessage={errorMessage.businessName}
          />
        </div>

        <div className="row">
          <TextInput
            className="col-6"
            label="Email"
            name="email"
            value={formData.email}
            onChange={onTextInputChange}
            errorMessage={errorMessage.email}
          />

          <TextInput
            className="col-6 mb-4"
            label="Phone Number"
            name="phone"
            value={formData.phone}
            onChange={onTextInputChange}
            errorMessage={errorMessage.phone}
          />
        </div>
      </section>

      <section className="row no-gutters mt-3 pb-5">
        <Button title="SAVE CHANGES" className="submit-btn pl-5 pr-5 ml-auto" onClick={onSubmit} />
      </section>
    </main>
  );
};

EditProfile.propTypes = {
  customer: PropTypes.object,
  getCurrentCustomerInfo: PropTypes.func,
  isFetchingCustomerInfo: PropTypes.bool,
  isUpdatingCustomer: PropTypes.bool,
  updateCustomerInfo: PropTypes.func,
};

export default EditProfile;
