import http from '../utils/http';
import * as endpoints from '../constants/endpoints';

export const createCheckoutSession = async ({ priceId, quantity, locationId, alias, customer }) => {
  const response = await http.post(endpoints.CHECKOUT_SESSION, {
    body: { priceId, quantity, locationId, alias, customer },
  });

  return response.data;
};

export const getCheckoutSession = async (sessionId) => {
  const response = await http.get(endpoints.CHECKOUT_SESSION, {
    params: { sessionId },
  });

  return response.data;
};
