import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import {
  FaPhone,
  FaClock,
  FaHeadset,
  FaEnvelope,
  FaFacebook,
  FaInstagram,
  FaLayerGroup,
  FaMapMarkerAlt,
  FaLocationArrow,
  FaFirefoxBrowser,
} from 'react-icons/fa';

import PlaceSearch from '../PlaceSearch';
import Button from '../../../../components/Button/Button';
import Loader from '../../../../components/Loader/Loader';
import TextInput from '../../../../components/TextInput/TextInput';
import PageTitle from '../../../../components/PageTitle/PageTitle';
import SupportLink from '../../../../components/SupportLink/SupportLink';
import SelectInput from '../../../../components/SelectInput/SelectInput';

import regex from 'constants/regex';
import { timeArrayOptions } from 'constants/timeArray';

import { isEmpty as isObjectEmpty } from 'utils/object';
import { addDataToForm, isFormValid } from 'utils/formValidation';
import { Link } from 'react-router-dom';
import routes from 'constants/routes';

const isValidPhoneNumber = (phone) => {
  if (!regex.phoneNumber.test(phone)) return false;
  return true;
};

function MyLocationForm(props) {
  const {
    pageTitle,
    formData = {},
    setFormData,
    errorMessage = {},
    setErrorMessage,
    onClear,
    onSubmit,
    isSubmitting,
    isGettingData,
    isLoadingCategory,
    isLoadingArea,
    areas,
    categories,
    getAreaList,
    getCategoryList,
  } = props;

  useEffect(() => {
    getAreaList();
    getCategoryList();
  }, [getAreaList, getCategoryList]);

  const handleOnSubmit = () => {
    if (!isFormValidated()) {
      return;
    }

    onSubmit();
  };

  const isFormValidated = () => {
    const requiredFields = {
      name: 'Name',
      areaId: 'Area',
      'businessHour.startTime': 'Start Time',
      'businessHour.endTime': 'End Time',
      categoryId: 'Category',
      phone: 'Phone Number',
      email: 'Email',
      placeId: 'Place',
    };

    const { isValid, validationMessage } = isFormValid(formData, requiredFields);

    setErrorMessage(validationMessage);

    return isValid;
  };

  const onTextInputChange = (name, value, splitName = false) => {
    const updatedFormData = addDataToForm(formData, name, value, splitName);

    setFormData(updatedFormData);

    if (name === 'phone' && !isValidPhoneNumber(value)) {
      setErrorMessage({
        ...errorMessage,
        [name]: 'Invalid Phone Number',
      });

      return;
    }

    setErrorMessage({});
  };

  const onPlaceSearchChange = (value) => {
    setFormData({
      ...formData,
      name: value.name || '',
      placeId: value.placeId || '',
      phone: value.phone || '',
      website: value.website || '',
      geolocation: value.geolocation || null,
      googleMapsUrl: value.googleMapsUrl,
      businessHours: value.businessHours || '',
      addressLine1: value.address || '',
    });

    setErrorMessage((errorMessage) => ({ ...errorMessage, placeId: '' }));
  };

  const placeSearchValue = {
    name: formData.name || '',
    googleMapsUrl: formData.googleMapsUrl,
  };

  if (isLoadingCategory || isGettingData || isLoadingArea || isSubmitting) {
    return <Loader />;
  }

  console.log('---form data', formData);

  return (
    <div className="w-100">
      <div className="d-flex justify-content-between mb-4">
        <PageTitle title={pageTitle} />
      </div>

      <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6">
        <div className="col-span-6 sm:col-span-3">
          <PlaceSearch
            className=""
            onChange={onPlaceSearchChange}
            value={placeSearchValue}
            name="name"
            title="Business Location Name"
            subtitle="Type in your business name so that we can find you on Google."
            errorMessage={errorMessage.placeId}
          />
        </div>
        <div className="col-span-6 sm:col-span-3">
          <div className="row">
            <div className="col-6 pl-4">
              <div className="mt-4">
                <label className="block text-sm font-medium text-gray-700">Opening hours</label>
                <div className="sm:flex sm:flex-wrap -mt-4">
                  <SelectInput
                    className="col-6"
                    // label="Opening Times"
                    placeholder="Start Time"
                    Icon={FaClock}
                    name="businessHour.startTime"
                    options={timeArrayOptions}
                    value={formData.businessHour.startTime}
                    onChange={(name, value) => onTextInputChange(name, value, true)}
                    errorMessage={errorMessage['businessHour.startTime']}
                  />
                  <label className="block text-sm font-medium text-gray-700 self-end mt-4 sm:mt-0 sm:mb-2 sm:mx-2">
                    to
                  </label>
                  <SelectInput
                    label=""
                    className="col-6 mt-3"
                    placeholder="End Time"
                    Icon={FaClock}
                    name="businessHour.endTime"
                    options={timeArrayOptions}
                    value={formData.businessHour.endTime}
                    onChange={(name, value) => onTextInputChange(name, value, true)}
                    errorMessage={errorMessage['businessHour.endTime']}
                  />
                </div>
              </div>

              <TextInput
                className="mb-4"
                label="Phone Number:"
                placeholder="+44 20 7234 3456"
                Icon={FaPhone}
                name="phone"
                value={formData.phone}
                onChange={onTextInputChange}
                errorMessage={errorMessage.phone}
              />
              <TextInput
                className="mb-4"
                label="Facebook Profile:"
                placeholder="https://www.facebook.com/mybusiness"
                Icon={FaFacebook}
                name="facebook"
                value={formData.facebook}
                onChange={onTextInputChange}
                errorMessage={errorMessage.facebook}
              />
              <TextInput
                className="mb-4"
                label="Instagram handle:"
                placeholder="my_business"
                Icon={FaInstagram}
                name="instagram"
                value={formData.instagram}
                onChange={onTextInputChange}
                errorMessage={errorMessage.instagram}
              />
              <TextInput
                className="mb-4"
                label="Address:"
                placeholder="London"
                Icon={FaMapMarkerAlt}
                name="addressLine1"
                value={formData.addressLine1}
                onChange={onTextInputChange}
                errorMessage={errorMessage.addressLine1}
              />
            </div>
            <div className="col-6 pl-4">
              <SelectInput
                className="mb-4"
                label="Area:"
                placeholder="Select an Area"
                Icon={FaLocationArrow}
                name="areaId"
                options={areas}
                optionKey="_id"
                optionLabel="name"
                value={formData.areaId}
                onChange={onTextInputChange}
                errorMessage={errorMessage.areaId}
              />
              <SelectInput
                className="mb-4"
                label="Category:"
                placeholder="Choose one"
                Icon={FaLayerGroup}
                name="categoryId"
                options={categories}
                optionKey="_id"
                optionLabel="name"
                value={formData.categoryId}
                onChange={onTextInputChange}
                errorMessage={errorMessage.categoryId}
              />
              <TextInput
                className="mb-4"
                label="Email:"
                placeholder="admin@newbusiness.com"
                name="email"
                Icon={FaEnvelope}
                value={formData.email}
                onChange={onTextInputChange}
                errorMessage={errorMessage.email}
              />
              <TextInput
                className="mb-4"
                label="Website:"
                placeholder="www.mynewbusiness.com"
                Icon={FaFirefoxBrowser}
                name="website"
                value={formData.website}
                onChange={onTextInputChange}
                errorMessage={errorMessage.website}
              />
            </div>
          </div>

          <div className="pt-8 flex justify-end">
            <Link
              to={routes.LOCATION}
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-blue-gray-900 hover:bg-blue-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Cancel
            </Link>
            <button
              type="submit"
              disabled={!isObjectEmpty(errorMessage)}
              onClick={handleOnSubmit}
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Save changes
            </button>
          </div>
        </div>
      </div>

      <div className="row no-gutters">
        <div className="mt-5 col-12">
          <div className="d-flex justify-content-end">
            <SupportLink title="CONTACT" link="#" className="mr-4" Icon={FaPhone} />
            <SupportLink title="SUPPORT" link="#" className="mr-4" Icon={FaHeadset} />
          </div>
        </div>
      </div>
    </div>
  );
}

MyLocationForm.propTypes = {
  areas: PropTypes.any,
  categories: PropTypes.any,
  errorMessage: PropTypes.object,
  formData: PropTypes.object,
  getAreaList: PropTypes.func,
  getCategoryList: PropTypes.func,
  isGettingData: PropTypes.any,
  isLoadingArea: PropTypes.any,
  isLoadingCategory: PropTypes.any,
  isSubmitting: PropTypes.any,
  onClear: PropTypes.any,
  onSubmit: PropTypes.func,
  pageTitle: PropTypes.any,
  setErrorMessage: PropTypes.func,
  setFormData: PropTypes.func,
};

export default MyLocationForm;
