import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FaInfoCircle } from 'react-icons/fa';
import React, { useEffect, useMemo } from 'react';

import Table from 'components/Table';
import Loader from '../../components/Loader/Loader';
import PageTitle from '../../components/PageTitle/PageTitle';

import ROUTES from '../../constants/routes';

import { interpolate, isEmpty as isStringEmpty } from '../../utils/string';
import classNames from 'classnames';

const MyDeals = (props) => {
  const { getDealList, deals, isLoading } = props;
  const tableData = deals;

  useEffect(() => {
    getDealList();
  }, [getDealList]);

  const columns = useMemo(
    () => [
      {
        Header: 'Deal',
        accessor: 'title',
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => (
          <div className="d-flex">
            <p className="m-0">{value}</p>
          </div>
        ),
      },
      {
        Header: 'Date Created',
        accessor: 'createdAt',
        Cell: (cellInfo) => {
          const value = cellInfo.cell.value;

          if (isStringEmpty(value)) return '-';
          return dayjs(value).format('DD/MM/YYYY');
        },
      },
      {
        Header: 'Location',
        accessor: (a) => {
          console.log('a', a.locations);
          return a.locations
            .filter((l) => l !== null)
            .map((l) => l.name)
            .join(', ');
        },
        id: 'location',
      },
      {
        Header: 'Status',
        accessor: 'status',
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => {
          const statusClassMaps = {
            approved: 'approved',
            denied: 'denied',
            pending: 'pending',
            cancelled: 'cancelled',
          };

          return (
            <span
              className={classNames('inline-flex rounded-full px-2 text-xs font-semibold leading-5', {
                'bg-green-100 text-green-800': value === 'approved',
                'bg-red-100 text-red-800': value === 'denied',
                'bg-yellow-100 text-yellow-800': value === 'pending',
              })}
            >
              {value}
            </span>
          );

          return <div className={`text-transform-capitalize ${statusClassMaps?.[value] || ''}`}>{value}</div>;
        },
      },
      {
        id: 'action',
        // eslint-disable-next-line react/prop-types
        Cell: ({ row: { original = {} } }) => {
          const editUrl = interpolate(ROUTES.EDIT_DEAL, { dealId: original?._id || '' });

          return (
            <Link className="text-decoration-none link-button color-windows-blue" to={editUrl}>
              Edit
            </Link>
          );
        },
      },
    ],
    []
  );

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="w-100">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-600">Your Deals</h1>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          {tableData.length > 0 && (
            <Link
              to={ROUTES.CREATE_DEAL}
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
            >
              Create new deal
            </Link>
          )}
        </div>
      </div>

      <div className="table-container">
        <Table
          data={tableData}
          columns={columns}
          noDataActionMessage="Add your first deal"
          noDataActionLink={ROUTES.CREATE_DEAL}
        />
      </div>

      {/* <div className="d-flex justify-content-between deals-info">
        <div className="d-flex align-items-center">
          <FaInfoCircle color="#007aff" /> &nbsp; Click to get more information.
        </div>
        <Link className="text-decoration-none" to={ROUTES.CREATE_DEAL}>
          <button className="d-flex align-items-center link-button">
            Add a new Deal &nbsp;
            <div className="d-flex align-items-center justify-content-center add-deal">+</div>
          </button>
        </Link>
      </div> */}
    </div>
  );
};

MyDeals.propTypes = {
  getDealList: PropTypes.func,
  deals: PropTypes.array,
  isLoading: PropTypes.bool,
};

export default MyDeals;
