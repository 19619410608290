import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';

import { getCurrentCustomerInfo, clearAuthState } from './redux/actions/auth';

import ROUTES from './constants/routes';
import { GET_CUSTOMER_INFO_FAIL } from './redux/types';

import Loader from './components/Loader';

const AuthenticatedRoutes = (props) => {
  const history = useHistory();
  const { getCurrentCustomerInfo, clearAuthState, isFetchingCustomerInfo, customer = {}, children } = props;

  useEffect(async () => {
    const response = await getCurrentCustomerInfo();

    if (response.type === GET_CUSTOMER_INFO_FAIL) {
      clearAuthState();
      history.replace(ROUTES.AUTH.LOGIN);
    }
  }, [getCurrentCustomerInfo, clearAuthState]);

  /**
   * Don't Move isFetching -> Loader and Children as in If Else.
   * If Customer data is fetching loader will be shown,
   * if there is valid customer data other valid authenticated route will be shown.
   *
   * The main problem lies here that, as children is main component mounting and unmounting those componet
   * has higher consequences and high render cost.
   *
   */
  return (
    <>
      {isFetchingCustomerInfo && <Loader />}
      {customer?.contacts?.email && children}
    </>
  );
};

AuthenticatedRoutes.propTypes = {
  children: PropTypes.any,
  clearAuthState: PropTypes.func.isRequired,
  customer: PropTypes.object,
  getCurrentCustomerInfo: PropTypes.func.isRequired,
  isFetchingCustomerInfo: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const customer = state.auth?.customer;

  return {
    customer,
    isFetchingCustomerInfo: state.auth.isLoading,
    error: state.auth.error,
  };
};

const mapDispatchToProps = { getCurrentCustomerInfo, clearAuthState };

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticatedRoutes);
