import {
  CLEAR_AUTH_STATE,
  CUSTOMER_LOGIN,
  CUSTOMER_LOGIN_FAIL,
  CUSTOMER_LOGIN_SUCCESS,
  GET_CUSTOMER_INFO,
  GET_CUSTOMER_INFO_FAIL,
  GET_CUSTOMER_INFO_SUCCESS,
  CUSTOMER_UPDATE,
  CUSTOMER_UPDATE_SUCCESS,
  CUSTOMER_UPDATE_FAIL,
} from '../types';

const initialState = {
  customer: {},
  isLoading: false,
  error: {},
  isUpdatingCustomer: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CUSTOMER_INFO: {
      return { ...state, isLoading: true };
    }

    case GET_CUSTOMER_INFO_SUCCESS: {
      const { payload } = action;

      return { ...state, customer: payload, isLoading: false };
    }

    case GET_CUSTOMER_INFO_FAIL: {
      const { payload } = action;

      return { ...state, error: payload, isLoading: false };
    }

    case CUSTOMER_LOGIN: {
      return { ...state, isLoading: true };
    }

    case CUSTOMER_LOGIN_SUCCESS: {
      const { payload } = action;

      return { ...state, customer: payload, isLoading: false };
    }

    case CUSTOMER_LOGIN_FAIL: {
      const { payload } = action;

      return { ...state, error: payload, isLoading: false };
    }

    case CUSTOMER_UPDATE: {
      return { ...state, isUpdatingCustomer: true };
    }

    case CUSTOMER_UPDATE_SUCCESS: {
      const { payload } = action;

      return { ...state, customer: payload, isUpdatingCustomer: false };
    }

    case CUSTOMER_UPDATE_FAIL: {
      const { payload } = action;

      return { ...state, error: payload, isUpdatingCustomer: false };
    }

    case CLEAR_AUTH_STATE: {
      return { ...initialState };
    }

    default:
      return state;
  }
};
