import { PropTypes } from 'prop-types';
import React, { useEffect } from 'react';
import { useTable, useSortBy, useGlobalFilter, usePagination, useExpanded } from 'react-table';
import { Link } from 'react-router-dom';

/**
 * Common Table Component.
 *
 * @param {Object} Props
 *
 * @returns {Component}
 */
function Table(props) {
  const {
    columns,
    data,
    isLoading = false,
    noDataMessage = 'No data found',
    showNoDataMessage = true,
    noDataActionLink,
    noDataActionOnClick,
    noDataActionMessage,
    sortBy = [],
    filterText,
  } = props;

  const { getTableProps, headerGroups, getTableBodyProps, rows, prepareRow, setGlobalFilter, footerGroups } = useTable(
    {
      columns,
      data,
      autoResetPage: false,
      initialState: {
        sortBy: sortBy,
        hiddenColumns: [],
      },
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination
  );

  useEffect(() => {
    setGlobalFilter(filterText);
  }, [setGlobalFilter, filterText]);

  return (
    <div className="mt-8 flex flex-col">
      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          {!rows.length && !isLoading && showNoDataMessage && noDataActionOnClick && (
            <button
              onClick={noDataActionOnClick}
              className="relative block w-1/3 mx-auto border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <svg
                className="mx-auto h-12 w-12 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 48 48"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M8 14v20c0 4.418 7.163 8 16 8 1.381 0 2.721-.087 4-.252M8 14c0 4.418 7.163 8 16 8s16-3.582 16-8M8 14c0-4.418 7.163-8 16-8s16 3.582 16 8m0 0v14m0-4c0 4.418-7.163 8-16 8S8 28.418 8 24m32 10v6m0 0v6m0-6h6m-6 0h-6"
                />
              </svg>
              <span className="mt-2 block text-sm font-medium text-gray-900">{noDataActionMessage}</span>
            </button>
          )}
          {!rows.length && !isLoading && showNoDataMessage && noDataActionLink && (
            <Link
              to={noDataActionLink}
              className="relative block w-1/3 mx-auto border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <svg
                className="mx-auto h-12 w-12 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 48 48"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M8 14v20c0 4.418 7.163 8 16 8 1.381 0 2.721-.087 4-.252M8 14c0 4.418 7.163 8 16 8s16-3.582 16-8M8 14c0-4.418 7.163-8 16-8s16 3.582 16 8m0 0v14m0-4c0 4.418-7.163 8-16 8S8 28.418 8 24m32 10v6m0 0v6m0-6h6m-6 0h-6"
                />
              </svg>
              <span className="mt-2 block text-sm font-medium text-gray-900">{noDataActionMessage}</span>
            </Link>
          )}
          {rows.length > 0 && !isLoading && (
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table {...getTableProps()} className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  {headerGroups.map((headerGroup, i) => (
                    <tr {...headerGroup.getHeaderGroupProps()} key={i}>
                      {headerGroup.headers.map((column, index) => (
                        <th
                          {...column.getHeaderProps()}
                          key={index}
                          width={column?.customWidth}
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          <div className="table-head-column-content">{column.render('Header')}</div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>

                <tbody {...getTableBodyProps()} className="divide-y divide-gray-200 bg-white">
                  {rows.map((row, i) => {
                    prepareRow(row);

                    return (
                      <tr {...row.getRowProps()} key={i}>
                        {row.cells.map((cell, index) => {
                          return (
                            <td
                              key={index}
                              {...cell.getCellProps()}
                              className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                            >
                              {cell.render('Cell')}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}

                  {!rows.length && !isLoading && showNoDataMessage && (
                    <tr>
                      <td colSpan={1000} style={{ paddingTop: 20, paddingBottom: 20 }}>
                        <span className="d-flex justify-content-center">{noDataMessage}</span>
                      </td>
                    </tr>
                  )}
                </tbody>

                <tfoot>
                  {footerGroups.map((group, i) => {
                    const isFooterAvailable =
                      group.headers.filter((column) => column.Footer && typeof column.Footer !== 'function').length > 0;

                    return (
                      isFooterAvailable && (
                        <tr {...group.getFooterGroupProps()} key={i}>
                          {group.headers.map((column, index) => {
                            return (
                              <td {...column.getFooterProps()} key={index}>
                                {column.render('Footer')}
                              </td>
                            );
                          })}
                        </tr>
                      )
                    );
                  })}
                </tfoot>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

Table.propTypes = {
  columns: PropTypes.any,
  data: PropTypes.any,
  filterText: PropTypes.any,
  isLoading: PropTypes.bool,
  noDataMessage: PropTypes.string,
  showNoDataMessage: PropTypes.bool,
  sortBy: PropTypes.array,
};

export default Table;
