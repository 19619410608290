import { connect } from 'react-redux';

import { clearAuthState } from '../../redux/actions/auth';

import Component from './Footer';

const mapStateToProps = (state, props) => {
  return {
    loggedInCustomer: state.auth.customer,
  };
};

const mapDispatchToProps = {
  clearAuthState,
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
