import { isEmpty as isArrayEmpty } from './array';
import { isEmpty as isStringEmpty } from './string';
import { get as objectGet, isEmpty as isObjectEmpty } from './object';

/**
 * Checks if the form is valid.
 *
 * @param {Object} formValues
 * @param {Object} requiredFields
 *
 * @returns {Object} obj
 * @returns {Boolean} obj.isValid
 * @returns {Object} obj.validationMessage
 */
export const isFormValid = (formValues, requiredFields) => {
  let isValid = true;
  let validationMessage = {};

  Object.keys(requiredFields).forEach((fieldName) => {
    const value = objectGet(formValues, fieldName);

    const isArrayValueEmpty = Array.isArray(value) && (isArrayEmpty(value) || !value?.[0]?.value);
    const isObjectValueEmpty =
      isObjectEmpty(value) || (value?.constructor === Object && !value?.value && value?.value !== 0);

    if (isStringEmpty(value) || isArrayValueEmpty || isObjectValueEmpty) {
      isValid = false;

      validationMessage = {
        ...validationMessage,
        [fieldName]: `${requiredFields[fieldName]} is required`,
      };
    }
  });

  return { isValid, validationMessage };
};

/**
 * Add Value to Form Object even if the name has nested dot.
 * E.g: formName=data1.test1.myData, then it gets added to formData, if splitName is true.
 *
 * @param {Object} formData
 * @param {string} formName
 * @param {*} value
 * @param {boolean} [splitName=false]
 *
 * @return {Object}
 */
export const addDataToForm = (formData, formName, value, splitName = false) => {
  let newData = {
    [formName]: value,
  };

  // Add 'data1.parent1.child1' data to form.
  if (splitName) {
    const nameList = formName.split('.');

    const parentDataName = nameList[0];
    const childDataName = nameList.pop();

    const updatedData = nameList.reduceRight(
      (acc, name, mainIndex) => {
        let oldData = formData;

        for (let index = 0; index <= nameList.length - 1 - mainIndex; index++) {
          const element = nameList[index];
          oldData = oldData[element];
        }

        acc = { ...oldData, ...acc };

        return acc;
      },
      {
        [childDataName]: value,
      }
    );

    newData = {
      [parentDataName]: updatedData,
    };
  }

  return {
    ...formData,
    ...newData,
  };
};
