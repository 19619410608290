import { connect } from 'react-redux';

import { getLocationList } from 'redux/actions/location';
import { getLocationOptions } from 'redux/selectors/location';

import Component from './MyDealsForm';

const mapStateToProps = (state, props) => {
  return {
    locations: getLocationOptions(state),
    isLoadingLocation: state.location.isLoading,
  };
};

const mapDispatchToProps = { getLocationList };

export default connect(mapStateToProps, mapDispatchToProps)(Component);
