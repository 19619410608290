import React, { useEffect } from 'react';

import { useLocation } from 'react-router-dom';
import { FaCheckCircle } from 'react-icons/fa';

import { getCheckoutSession } from 'service/paymentSession';

const PaymentSuccess = (props) => {
  const search = useLocation().search;
  const sessionId = new URLSearchParams(search).get('session_id');

  useEffect(() => {
    async function fetchCheckoutSession() {
      // TODO: Might be needed in future
      await getCheckoutSession(sessionId);
    }

    fetchCheckoutSession();
  }, []);

  return (
    <div className="payment-success-wrapper d-flex flex-column align-items-center w-100">
      <FaCheckCircle size={120} className="color-primary-green" />
      <div className="title mt-4">Payment Successful</div>
      <div className="mt-2">Successfully Subscribed</div>
    </div>
  );
};

export default PaymentSuccess;
