import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FaInfoCircle } from 'react-icons/fa';
import React, { useEffect, useMemo } from 'react';

import Table from 'components/Table';
import Loader from '../../components/Loader/Loader';
import PageTitle from '../../components/PageTitle/PageTitle';

import ROUTES from '../../constants/routes';

import { interpolate } from '../../utils/string';

const MyLocation = (props) => {
  const { getLocationList, locations, isLoading } = props;

  useEffect(() => {
    getLocationList();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: 'Location',
        accessor: 'name',
      },
      {
        Header: 'Business Type',
        accessor: 'category.title',
      },
      {
        Header: 'Address',
        accessor: 'addressLine1',
      },
      {
        Header: 'Status',
        accessor: 'status',
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => {
          const statusClassMaps = {
            Active: 'approved',
            Inactive: 'denied',
            pending: 'pending',
            cancelled: 'cancelled',
          };

          return (
            <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
              {value}
            </span>
          );
        },
      },
      {
        id: 'action',
        // eslint-disable-next-line react/prop-types
        Cell: ({ row: { original = {} } }) => {
          const editUrl = interpolate(ROUTES.EDIT_LOCATION, { locationId: original?._id || '' });

          return (
            <Link className="text-decoration-none link-button color-windows-blue" to={editUrl}>
              Edit
            </Link>
          );
        },
      },
    ],
    []
  );

  const tableData = useMemo(() => {
    const extractTableData = (locations) =>
      locations.map((location) => ({
        ...location,
        status: location?.isActive === true ? 'Active' : 'Inactive',
      }));

    // TODO: Remove mock data usage
    const locationData = locations;
    const tableData = extractTableData(locationData);

    return tableData;
  }, [locations]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="w-100">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-600">Your locations</h1>
          {/* <p className="mt-2 text-sm text-gray-700">
            A list of all the users in your account including their name, title, email and role.
          </p> */}
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          {tableData.length > 0 && (
            <Link
              to={ROUTES.ADD_LOCATION}
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
            >
              Add location
            </Link>
          )}
        </div>
      </div>

      <div className="table-container">
        <Table
          data={tableData}
          columns={columns}
          noDataActionMessage="Add your Locaction"
          noDataActionLink={ROUTES.ADD_LOCATION}
        />
      </div>
    </div>
  );
};

MyLocation.propTypes = {
  getLocationList: PropTypes.func,
  locations: PropTypes.array,
  isLoading: PropTypes.bool,
};
export default MyLocation;
