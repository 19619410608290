import React from 'react';
import PropTypes from 'prop-types';

import CreatableSelect from 'react-select/creatable';
import classnames from 'classnames';

const CreatableSelectInput = (props) => {
  const {
    label,
    placeholder = '',
    onChange = () => {},
    value,
    Icon,
    className,
    labelClassName = '',
    errorMessage,
    name,
    isMulti = false,
    options = [],
  } = props;

  let selectedValue;

  if (isMulti) {
    selectedValue = options?.filter((option) => value?.find(({ value }) => value === option?.value)) || [{}];
  } else {
    selectedValue = options?.find((option) => option?.value === value?.value) || {};
  }

  return (
    <div
      className={classnames('text-input', {
        [className]: className,
      })}
    >
      {label && (
        <div
          className={classnames('text-input__label', {
            [labelClassName]: labelClassName,
          })}
        >
          {label}
        </div>
      )}
      <div
        className={classnames('text-input__input', {
          'text-input__input--no-icon': !Icon,
        })}
      >
        {Icon && <Icon className="text-input__input__icon" />}
        <CreatableSelect
          isClearable
          value={selectedValue}
          options={options}
          isMulti={isMulti}
          styles={{
            control: (provided) => ({
              ...provided,
              fontSize: 12,
              borderWidth: 0,
              paddingLeft: 24,
              backgroundColor: 'none',
            }),
          }}
          onChange={(value) => onChange(name, value)}
          placeholder={placeholder}
        />
      </div>
      {errorMessage && <div className="text-input__info">{errorMessage}</div>}
    </div>
  );
};

CreatableSelectInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  label: PropTypes.string,
  isMulti: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.array,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  Icon: PropTypes.elementType,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
};

export default CreatableSelectInput;
