/**
 * Check if object is empty or not.
 *
 * @param {Object} object
 */
export const isEmpty = (object) => !object || (Object.entries(object).length === 0 && object.constructor === Object);

/**
 *
 * @param {Object} object Object to be traveresed.
 * @param {String} key Field name in object. Also can be nested.
 * @param {String} defaulvalue default value if not found.
 */
export const get = (object, key, defaulvalue) => {
  const keyList = key.split('.');

  let value;
  try {
    value = keyList.reduce((acc, item) => acc[item], object);
  } catch (err) {
    value = defaulvalue;
  }

  return value || defaulvalue;
};

/**
 * Get the copy of object without attributes.
 *
 * @param  {Object} obj
 * @param  {Array} attrsToExclude
 * @return {Object}
 */
export const withoutAttrs = (obj, attrsToExclude = []) => {
  const result = {};

  Object.keys(obj).forEach((key) => {
    if (!attrsToExclude.includes(key)) {
      result[key] = obj[key];
    }
  });

  return result;
};

/**
 * Check if provided keys exist in obj and value are not null
 * @param {Object} obj
 * @param {Array} keys
 */
export const keyExistsNotNull = (obj, keys = []) => {
  return keys.every((key) => !!obj[key]);
};

/**
 * Check if provided keys are empty in object or not.
 * @param {Object} obj
 * @param {Array} keys
 * @returns
 */
export const validateIfFieldsAreEmpty = (obj, keys = []) => {
  let errorMsg = {};

  keys.forEach((key) => {
    if (!obj[key]) {
      errorMsg = {
        ...errorMsg,
        [key]: `${key} is required.`,
      };
    }
  });

  return errorMsg;
};
