import 'izitoast/dist/css/iziToast.css';

import iziToast from 'izitoast';

iziToast.settings({
  timeout: 50000,
  resetOnHover: true,
  progressBar: false,
  transitionIn: 'fadeInUp',
  transitionOut: 'fadeOutDown',
  position: 'topCenter',
  displayMode: 2,
  backgroundColor: 'rgb(37, 99, 235)',
  titleColor: '#fff',
  messageColor: '#fff',
  iconColor: 'white',
});

export const success = ({ title, message, extraSettingParams = {} }) => {
  iziToast.success({
    title,
    message,
    ...extraSettingParams,
  });
};

export const error = ({ title, message, extraSettingParams = {} }) => {
  iziToast.error({
    title,
    message,
    ...extraSettingParams,
  });
};

export const warning = ({ title, message, extraSettingParams = {} }) => {
  iziToast.warning({
    title,
    message,
    ...extraSettingParams,
  });
};

export const info = ({ title, message, extraSettingParams = {} }) => {
  iziToast.info({
    title,
    message,
    ...extraSettingParams,
  });
};

export default {
  success,
  error,
  warning,
  info,
};
