import PropTypes from 'prop-types';
import React, { useState, useMemo, useEffect } from 'react';

import Table from '../../components/Table';
import Loader from '../../components/Loader/Loader';
import PageTitle from '../../components/PageTitle/PageTitle';
import EditBillingDetailModal from './Components/EditBillingDetailModal';

import { isEmpty as isObjectEmpty } from 'utils/object';
import { isEmpty as isStringEmpty } from 'utils/string';
import { cancelSubscription } from 'service/subscription';
import CancelSubscriptionModal from './Components/CancelSubscriptionModal';
import ROUTES from 'constants/routes';

const Billing = (props) => {
  const { isLoading, subscriptions } = props;

  const [selectedBillingItem, setSelectedBillingItem] = useState({});
  const [showEditBillingDetailModal, setShowEditBillingDetailModal] = useState(false);
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [aggregate, setAggregate] = useState({ grossTotal: null, vat: null, total: null, taxRate: null });

  const columns = useMemo(
    () => [
      {
        Header: 'Location',
        accessor: 'location.name',
      },
      {
        Header: ' Boosts',
        accessor: 'quantity',
        // eslint-disable-next-line react/prop-types
        Cell: ({ row: { original }, value }) => {
          // eslint-disable-next-line react/prop-types
          const plan = original?.plan;
          return (
            <div>
              {!isStringEmpty(value) && !isObjectEmpty(plan) ? `${value}/${plan.interval_count}${plan.interval}` : '-'}
            </div>
          );
        },
      },
      {
        Header: 'Cost',
        accessor: 'plan',
        // eslint-disable-next-line react/prop-types
        Cell: ({ value: plan }) => {
          return (
            <div>{!isObjectEmpty(plan) ? `£${plan?.amount / 10}/${plan.interval_count}${plan.interval}` : '-'}</div>
          );
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => {
          return <div className="text-transform-capitalize">{value || 'inactive'}</div>;
        },
      },
      {
        id: 'action',
        Header: 'Action',
        accessor: 'status',
        // eslint-disable-next-line react/prop-types
        Cell: ({ row: { original }, value: status }) => {
          return (
            <>
              <button
                className="link-button color-windows-blue"
                onClick={() => {
                  handleOnEditBoost(original);
                }}
              >
                Edit
              </button>

              <button
                disabled={status !== 'active'}
                className="link-button color-secondary-red"
                onClick={() => {
                  handleOnCancelSubscription(original);
                }}
              >
                Cancel
              </button>
            </>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    props.getLocationList();
    props.getSubscriptionList();
  }, []);

  useEffect(() => {
    const taxRate =
      subscriptions.find((item) => item?.default_tax_rates?.[0].percentage)?.default_tax_rates?.[0].percentage || 0;
    let grossTotal = 0;

    subscriptions.forEach((item) => {
      const quantity = item?.quantity || 0;
      let price = item?.plan?.amount || 0;
      price = price / 100; // Need to divide to get exact price from Stripe prices

      grossTotal += price * quantity;
    });

    const vat = (grossTotal * taxRate) / 100;
    const total = grossTotal + vat;

    setAggregate({
      grossTotal: grossTotal,
      total: total,
      vat: vat,
      taxRate: taxRate,
    });
  }, [subscriptions]);

  const handleEditBillingDetailModalClose = () => {
    setSelectedBillingItem({});
    setShowEditBillingDetailModal(false);
  };

  const handleOnAddBoost = () => {
    setShowEditBillingDetailModal(true);
    props.getBoostsInfo();
  };

  const handleOnEditBoost = (billingItem) => {
    props.getBoostsInfo();
    setSelectedBillingItem(billingItem);
    setShowEditBillingDetailModal(true);
  };

  const handleOnCancelSubscription = (billingItem) => {
    setSelectedBillingItem(billingItem);
    setShowCancelDialog(true);
  };
  const handleOnCloseCancelSubscriptionDialog = () => {
    setSelectedBillingItem({});
    setShowCancelDialog(false);
  };

  const handleOnCancelSubscriptionSuccess = async () => {
    await cancelSubscription(selectedBillingItem._id);

    props.getSubscriptionList();

    setSelectedBillingItem({});
    setShowCancelDialog(false);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="w-100">
      <div className="d-flex justify-content-between mb-4">
        <PageTitle title="Billing" />
      </div>

      <div>
        <div className="table-container">
          <Table
            data={subscriptions}
            columns={columns}
            noDataActionMessage="Set up your payments"
            noDataActionOnClick={handleOnAddBoost}
          />
        </div>

        <div className="p-3 d-flex justify-content-between">
          <div>
            <div className="d-flex">
              <span className="fw-700 edit-profile__minw-250px">Gross Total:</span>
              <span>{`£${aggregate.grossTotal || 0}`}</span>
            </div>

            <div className="d-flex mt-2">
              <span className="fw-700 edit-profile__minw-250px">{`VAT (Charged at ${aggregate.taxRate || 0}%):`}</span>
              <span>{`£${aggregate.vat || 0}`}</span>
            </div>

            <div className="d-flex mt-2">
              <span className="fw-700 edit-profile__minw-250px">Total:</span>
              <span>{`£${aggregate.total}`}</span>
            </div>
          </div>

          <button className="d-flex align-items-center link-button" onClick={handleOnAddBoost}>
            Add New Billing&nbsp;
            <div className="d-flex align-items-center justify-content-center add-billing">+</div>
          </button>
        </div>
      </div>

      {showEditBillingDetailModal && (
        <EditBillingDetailModal
          boosts={props.boosts}
          taxRates={props.taxRates}
          locations={props.locations}
          open={showEditBillingDetailModal}
          isLoading={props.isBillingLoading}
          billingAccounts={props.billingAccounts}
          billingDetailData={selectedBillingItem}
          onModalClose={handleEditBillingDetailModalClose}
        />
      )}
      {showCancelDialog && (
        <CancelSubscriptionModal
          open={showCancelDialog}
          billingDetailData={selectedBillingItem}
          onModalClose={handleOnCloseCancelSubscriptionDialog}
          onSuccess={handleOnCancelSubscriptionSuccess}
        />
      )}
    </div>
  );
};

Billing.propTypes = {
  boosts: PropTypes.array,
  isLoading: PropTypes.bool,
  taxRates: PropTypes.array,
  locations: PropTypes.array,
  subscriptions: PropTypes.array,
  getBoostsInfo: PropTypes.func,
  getLocationList: PropTypes.func,
  isBillingLoading: PropTypes.bool,
  billingAccounts: PropTypes.array,
  getSubscriptionList: PropTypes.func,
};

export default Billing;
