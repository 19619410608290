import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ButtonLoader from './ButtonLoader.js';

const Button = (props) => {
  const { title, onClick, className, disabled, type, isLoading, loaderTheme } = props;
  return (
    <button
      type={type}
      disabled={disabled}
      onClick={onClick}
      className={classnames('btn', {
        [className]: className,
      })}
    >
      {isLoading ? <ButtonLoader className={loaderTheme} /> : title}
    </button>
  );
};

Button.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  type: PropTypes.string,
  isLoading: PropTypes.bool,
  loaderTheme: PropTypes.string,
};

export default Button;
