import { combineReducers } from 'redux';

import auth from './auth';
import area from './area';
import deal from './deal';
import billing from './billing';
import category from './category';
import location from './location';
import subscription from './subscription';

export const rootReducer = combineReducers({
  auth,
  area,
  deal,
  category,
  location,
  billing,
  subscription,
});
