import { connect } from 'react-redux';

import { getLocationList } from '../../redux/actions/location';

import Component from './MyLocation';

const mapStateToProps = (state, props) => {
  return {
    isLoading: state.location.isLoading,
    locations: state.location.locations,
  };
};

const mapDispatchToProps = { getLocationList };

export default connect(mapStateToProps, mapDispatchToProps)(Component);
