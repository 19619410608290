import http from '../utils/http';
import { get, remove, set } from '../utils/localstorage';

import * as endpoints from '../constants/endpoints';
import { ACCESS_TOKEN, REFRESH_TOKEN } from '../constants/appConstant';

export const refreshAccessToken = async (refreshToken) => {
  const {
    data: { accessToken },
  } = await http.post(endpoints.REFRESH_ACCESS_TOKEN, {
    body: {
      refreshToken,
    },
  });

  set(ACCESS_TOKEN, accessToken);

  return accessToken;
};

/**
 * Sets access token and refresh token.
 * @param {String} accessToken
 * @param {String} refreshToken
 */
export const setAuthTokens = (accessToken, refreshToken) => {
  set(ACCESS_TOKEN, accessToken);
  set(REFRESH_TOKEN, refreshToken);
};

/**
 * Returns access token.
 */
export const getAccessToken = () => get(ACCESS_TOKEN);

/**
 * Returns refresh token.
 */
export const getRefreshToken = () => get(REFRESH_TOKEN);

/**
 * Clears auth token.
 */
export const clearAuthTokens = () => {
  remove(ACCESS_TOKEN);
  remove(REFRESH_TOKEN);
};

export const login = async (email, password) => {
  const { data } = await http.post(endpoints.LOGIN_CUSTOMER, {
    body: {
      email,
      password,
    },
  });

  const { tokens } = data;
  setAuthTokens(tokens.accessToken, tokens.refreshToken);

  return data;
};

export const signup = async (customer) => {
  const { data } = await http.post(endpoints.SIGNUP_CUSTOMER, {
    body: customer,
  });

  return data;
};
