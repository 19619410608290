import React from 'react';
import PropTypes from 'prop-types';

import Select from 'react-select';
import classnames from 'classnames';

import { SelectBox } from './SelectBox';

const SelectInput = (props) => {
  const {
    label,
    placeholder = '',
    onChange = () => {},
    value,
    Icon,
    className,
    labelClassName = '',
    errorMessage,
    name,
    isMulti = false,
    options = [],
    disabled = false,
  } = props;

  let selectedValue;

  if (isMulti) {
    selectedValue = options?.filter((option) => value?.find(({ value }) => value === option?.value)) || [{}];
  } else {
    selectedValue = options?.find((option) => option?.value === value?.value) || {};
  }

  const handleChange = (selectedOption) => {
    onChange(name, selectedOption);
  };

  // let displayValue = {
  //   value: value.value,
  //   label: value.label ? value.label : options.find((o) => o.value === value.value)?.label || placeholder,
  // };

  let displayValue = null;

  return (
    <div className="mt-4">
      <label className="block text-sm font-medium text-gray-700">{label}</label>
      <div className="mt-1">
        <SelectBox
          options={options}
          onChange={handleChange}
          value={displayValue}
          multiple={isMulti}
          placeholder={placeholder}
        />
      </div>
      {errorMessage && (
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {errorMessage}
        </p>
      )}
    </div>
  );

  return (
    <div
      className={classnames('text-input', {
        [className]: className,
      })}
    >
      {label && (
        <div
          className={classnames('text-input__label', {
            [labelClassName]: labelClassName,
          })}
        >
          {label}
        </div>
      )}
      <div
        className={classnames('text-input__input', {
          'text-input__input--no-icon': !Icon,
        })}
      >
        {Icon && <Icon className="text-input__input__icon" />}
        <Select
          isDisabled={disabled}
          value={selectedValue}
          options={options}
          isMulti={isMulti}
          styles={{
            control: (provided) => ({
              ...provided,
              fontSize: 12,
              borderWidth: 0,
              paddingLeft: 24,
              backgroundColor: 'none',
            }),
          }}
          onChange={(value) => onChange(name, value)}
          placeholder={placeholder}
        />
      </div>
      {errorMessage && <div className="text-input__info">{errorMessage}</div>}
    </div>
  );
};

export default SelectInput;
