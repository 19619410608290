import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import ROUTES from './constants/routes';

import AddDeal from './screens/AddDeal';
import MyDeals from './screens/MyDeals';
import Billing from './screens/Billing';
import Login from './screens/Auth/Login';
import EditDeal from './screens/EditDeal';
import NotFound from './screens/NotFound';
import SignUp from './screens/Auth/SignUp';
import MyLocation from './screens/MyLocation';
import EditProfile from './screens/EditProfile';
import AddLocation from './screens/AddLocation';
import EditLocation from './screens/EditLocation';
import PaymentFailure from 'screens/PaymentFailure/PaymentFailure';
import PaymentSuccess from './screens/PaymentSuccess/PaymentSuccess';

import Header from './components/Header';
import Footer from './components/Footer';

import WithSideNav from './WithSideNav';
import AuthenticatedRoutes from './AuthenticatedRoutes';

const Router = () => {
  return (
    <>
      <Header />
      <Switch>
        <Route exact path={ROUTES.AUTH.LOGIN} component={Login} />
        <Route exact path={ROUTES.AUTH.SIGN_UP} component={SignUp} />
        <Route exact path={ROUTES.NOT_FOUND} component={NotFound} />

        <AuthenticatedRoutes>
          <div className="mx-auto pt-16 px-2 sm:px-6 lg:px-8 bg-gray-100">
            <Route exact path={ROUTES.HOME} component={AddDeal} />
            <Route exact path={ROUTES.USER.EDIT_PROFILE} component={EditProfile} />

            <Route exact path={ROUTES.DEALS} component={MyDeals} />
            <Route exact path={ROUTES.CREATE_DEAL} component={AddDeal} />
            <Route exact path={ROUTES.EDIT_DEAL} component={EditDeal} />

            <Route exact path={ROUTES.LOCATION} component={MyLocation} />
            <Route exact path={ROUTES.ADD_LOCATION} component={AddLocation} />
            <Route exact path={ROUTES.EDIT_LOCATION} component={EditLocation} />

            <Route exact path={ROUTES.SPENDING} component={Billing} />
            <Route exact path={ROUTES.ANALYTICS} component={AddLocation} />
            <Route exact path={ROUTES.PAYMENT_SUCCESS} component={PaymentSuccess} />
            <Route exact path={ROUTES.PAYMENT_FAIL} component={PaymentFailure} />
          </div>
        </AuthenticatedRoutes>

        <Redirect to={ROUTES.NOT_FOUND} />
      </Switch>
      <Footer />
    </>
  );
};

export default Router;
