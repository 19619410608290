import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import ROUTES from '../../../constants/routes';

import * as toast from '../../../utils/toast';
import * as authService from '../../../service/auth';
import { handleError } from '../../../utils/errorHandler';
import { isFormValid } from '../../../utils/formValidation';
import { isEmpty as isObjectEmpty, withoutAttrs } from '../../../utils/object';

import Button from '../../../components/Button/Button';
import TextInput from '../../../components/TextInput/TextInput';

import WelcomeVector from '../../../assets/images/welcome.svg';

import hourlyCombinationMarkLogo from '../../../assets/images/hourly-logo-combination-mark.png';

const initialFormData = {
  fullName: '',
  businessName: '',
  password: '',
  confirmationPassword: '',
  contacts: {
    email: '',
    phone: '',
  },
  imageUrl: '',
};

const SignUp = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [errorMessage, setErrorMessage] = useState({});
  const [acceptedTerms, setAcceptedTerms] = useState(false);

  const onAcceptTermsChange = (event) => {
    setAcceptedTerms(!acceptedTerms);
  };

  const onTextInputChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });

    setErrorMessage({});
  };

  const onContactChange = (name, value) =>
    onTextInputChange('contacts', {
      ...formData.contacts,
      [name]: value,
    });

  const signupCustomer = async (customerData) => {
    try {
      setIsSubmitting(true);
      await authService.signup(customerData);
      toast.success({
        title: 'Success',
        message:
          'Your Customer account has been registered. Please verify it by clicking the activation link that has been send to your email.',
      });
      setIsSubmitting(false);
      setFormData(initialFormData);
    } catch (error) {
      setIsSubmitting(false);
      handleError(error);
    }
  };

  const handleSignupClick = (e) => {
    e.preventDefault();
    const requiredFields = {
      fullName: 'Full Name',
      businessName: 'Business Name',
      password: 'Password',
      confirmationPassword: 'Confirmation Password',
      'contacts.email': 'Email',
      'contacts.phone': 'Phone',
    };

    const { isValid, validationMessage } = isFormValid(formData, requiredFields);

    if (!isValid) {
      return setErrorMessage(validationMessage);
    }

    if (formData.password.length < 6) {
      return setErrorMessage({
        password: 'Password must have length greater than or equal to 6',
      });
    }

    if (formData.password !== formData.confirmationPassword) {
      return setErrorMessage({
        confirmationPassword: "Password doesn't match",
      });
    }

    if (!acceptedTerms) {
      return setErrorMessage({
        acceptTerms: 'You must accept the Terms and Conditions',
      });
    }

    signupCustomer(withoutAttrs(formData, ['confirmationPassword']));
  };

  const isButtonDisabled = !isObjectEmpty(errorMessage) || isSubmitting;

  return (
    <>
      <div className="min-h-full flex">
        <div className="hidden lg:block relative flex-1">
          <img
            className="absolute inset-0 h-full w-full object-cover saturate-50 brightness-75"
            src="https://images.unsplash.com/photo-1600093463592-8e36ae95ef56?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2340&q=80"
            alt=""
          />
        </div>
        <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full  max-w-sm lg:w-96">
            <div>
              <img className="h-12 w-auto" src={hourlyCombinationMarkLogo} alt="Hourly app" />
              <h2 className="mt-6 text-3xl font-extrabold text-gray-900">Create your account</h2>
              <p className="mt-2 text-sm text-gray-600">
                Or{' '}
                <Link to={ROUTES.AUTH.LOGIN} className="font-medium text-indigo-600 hover:text-indigo-500">
                  log in if you already have one
                </Link>
              </p>
            </div>

            <div className="mt-8">
              <div className="mt-6">
                <form onSubmit={handleSignupClick} className="space-y-6">
                  <div>
                    <label htmlFor="fullName" className="block text-sm font-medium text-gray-700">
                      Full name
                    </label>
                    <div className="mt-1">
                      <input
                        id="fullName"
                        name="fullName"
                        onChange={(event) => onTextInputChange('fullName', event.target.value)}
                        type="text"
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                      {errorMessage.fullName && <p className="mt-2 text-sm text-red-600">{errorMessage.fullName}</p>}
                    </div>
                  </div>

                  <div>
                    <label htmlFor="businessName" className="block text-sm font-medium text-gray-700">
                      Company name
                    </label>
                    <div className="mt-1">
                      <input
                        id="businessName"
                        name="businessName"
                        onChange={(event) => onTextInputChange('businessName', event.target.value)}
                        type="text"
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                      {errorMessage.businessName && (
                        <p className="mt-2 text-sm text-red-600">{errorMessage.businessName}</p>
                      )}
                    </div>
                  </div>

                  <div>
                    <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                      Phone number
                    </label>
                    <div className="mt-1">
                      <input
                        id="phone"
                        name="phone"
                        onChange={(event) => onContactChange('phone', event.target.value)}
                        value={formData.contacts.phone}
                        type="text"
                        autoComplete="tel"
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                      {errorMessage['contacts.phone'] && (
                        <p className="mt-2 text-sm text-red-600">{errorMessage['contacts.phone']}</p>
                      )}
                    </div>
                  </div>

                  <div>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                      Email address
                    </label>
                    <div className="mt-1">
                      <input
                        id="email"
                        name="email"
                        onChange={(event) => onContactChange('email', event.target.value)}
                        value={formData.contacts.email}
                        type="email"
                        autoComplete="email"
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                      {errorMessage['contacts.email'] && (
                        <p className="mt-2 text-sm text-red-600" id="email-error">
                          {errorMessage['contacts.email']}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="space-y-1">
                    <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                      Password
                    </label>
                    <div className="mt-1">
                      <input
                        id="password"
                        name="password"
                        onChange={(event) => onTextInputChange('password', event.target.value)}
                        type="password"
                        autoComplete="current-password"
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                      {errorMessage.password && (
                        <p className="mt-2 text-sm text-red-600" id="email-error">
                          {errorMessage.password}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="space-y-1">
                    <label htmlFor="confirmationPassword" className="block text-sm font-medium text-gray-700">
                      Password again
                    </label>
                    <div className="mt-1">
                      <input
                        id="confirmationPassword"
                        name="confirmationPassword"
                        onChange={(event) => onTextInputChange('confirmationPassword', event.target.value)}
                        type="password"
                        autoComplete="current-password"
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                      {errorMessage.confirmationPassword && (
                        <p className="mt-2 text-sm text-red-600" id="email-error">
                          {errorMessage.confirmationPassword}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <input
                        id="acceptTerms"
                        name="acceptTerms"
                        type="checkbox"
                        value={acceptedTerms}
                        onChange={onAcceptTermsChange}
                        className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                      />
                      <label htmlFor="acceptTerms" className="ml-2 block text-sm text-gray-900">
                        I accept{' '}
                        <a href="/" className="font-medium text-indigo-600 hover:text-indigo-500">
                          Terms and Conditions
                        </a>{' '}
                        and{' '}
                        <a href="/" className="font-medium text-indigo-600 hover:text-indigo-500">
                          Privacy Policy
                        </a>
                      </label>
                    </div>

                    <div className="text-sm">
                      {/* <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                        Forgot your password?
                      </a> */}
                    </div>
                  </div>
                  {errorMessage.acceptTerms && (
                    <p className="mt-2 text-sm text-red-600" id="email-error">
                      {errorMessage.acceptTerms}
                    </p>
                  )}

                  <div>
                    <button
                      type="submit"
                      className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Sign in
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <div className="sign-up-container">
      <div className="sign-up-card">
        <div className="sign-up-card__welcome-card">
          <img src={WelcomeVector} alt="welcome image" />
          <div className="sign-up-card-text">
            <div className="sign-up-card-text__title">WELCOME TO HOURLY</div>
            <div className="sign-up-card-text__sub-title">A great deal, every hour</div>
          </div>
        </div>

        <div className="sign-up-card__vertical-border" />

        <div className="sign-up-card__form sign-up-form-container">
          <div className="sign-up-form-container__title mb-2">MEMBER SIGN UP</div>
          <div className="sign-up-form-container__sub-title mb-3">
            Build offers with our team to drive customers to you, when you need them.
          </div>

          <form onSubmit={handleSignupClick}>
            <div>
              <TextInput
                className="mb-3"
                name="fullName"
                value={formData.fullName}
                errorMessage={errorMessage.fullName}
                onChange={onTextInputChange}
                placeholder="Full name"
              />
              <TextInput
                className="mb-3"
                name="businessName"
                value={formData.businessName}
                errorMessage={errorMessage.businessName}
                onChange={onTextInputChange}
                placeholder="Business name"
              />
              <TextInput
                className="mb-3"
                type="email"
                name="email"
                value={formData.contacts.email}
                errorMessage={errorMessage['contacts.email']}
                onChange={onContactChange}
                placeholder="Email"
              />
              <TextInput
                className="mb-3"
                name="phone"
                value={formData.contacts.phone}
                errorMessage={errorMessage['contacts.phone']}
                onChange={onContactChange}
                placeholder="Phone"
              />
              <TextInput
                className="mb-3"
                type="password"
                name="password"
                value={formData.password}
                errorMessage={errorMessage.password}
                onChange={onTextInputChange}
                placeholder="Password"
              />
              <TextInput
                className="mb-3"
                type="password"
                name="confirmationPassword"
                value={formData.confirmationPassword}
                errorMessage={errorMessage.confirmationPassword}
                onChange={onTextInputChange}
                placeholder="Re-type Password"
              />
              <div className="d-flex justify-content-between mt-5">
                <Link className="text-decoration-none" to={ROUTES.AUTH.LOGIN}>
                  <Button type="button" title="LOGIN" className="button-sign-in" />
                </Link>
                <Button
                  disabled={isButtonDisabled}
                  title="SIGN UP"
                  className={isButtonDisabled ? 'button-login btn-disabled' : 'button-login'}
                  type="submit"
                  isLoading={isSubmitting}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
