import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { FaMapMarkerAlt, FaCcStripe } from 'react-icons/fa';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import Loader from 'components/Loader';
import Button from 'components/Button/Button';
import SelectInput from 'components/SelectInput/SelectInput';
import CreatableSelectInput from 'components/SelectInput/CreatableSelectInput';

import config from 'config';
import { isEmpty as isStringEmpty } from 'utils/string';
import { isEmpty as isObjectEmpty, keyExistsNotNull, validateIfFieldsAreEmpty } from 'utils/object';

import { createCheckoutSession } from 'service/paymentSession';
import { updateSubscription } from 'service/subscription';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '12px',
    padding: '30px 35px',
    height: '96vh',
    overflowY: 'auto',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 999,
  },
};

function EditBillingDetailModal(props) {
  const stripePromise = loadStripe(config.stripePublishableKey);
  const {
    boosts,
    taxRates,
    isLoading,
    locations,
    open = false,
    billingAccounts,
    billingDetailData,
    onModalClose = () => {},
  } = props;

  const [formData, setFormData] = useState({
    alias: null,
    price: null,
    payment: null,
    location: null,
    boostsCount: 20,
  });
  const [errorMessage, setErrorMessage] = useState({});
  const [currentBillingAccounts, setCurrentBillingAccounts] = useState([]);

  useEffect(() => {
    setFormData({
      ...formData,
      price: boosts?.[0],
    });
  }, [boosts]);

  useEffect(() => {
    if (isObjectEmpty(billingDetailData)) {
      return;
    }

    const currentPayment = billingAccounts.find((account) => account.value === billingDetailData.customer);
    const currentLocation = locations.find((location) => location.value === billingDetailData.locationId);
    const boostsCount = billingDetailData.quantity;
    const price = boosts.find((boost) => boost.id === billingDetailData?.plan?.id);

    setFormData({
      ...formData,
      price,
      payment: currentPayment,
      location: currentLocation,
      boostsCount: boostsCount,
    });
  }, [billingDetailData, billingAccounts, locations, boosts]);

  useEffect(() => {
    setCurrentBillingAccounts(billingAccounts);
  }, [billingAccounts]);

  const onPaymentUpdate = async (event) => {
    event.preventDefault();

    const requiredFields = ['price', 'location', 'boostsCount', 'payment'];

    if (!keyExistsNotNull(formData, requiredFields)) {
      const errMsg = validateIfFieldsAreEmpty(formData, requiredFields);

      setErrorMessage(errMsg);
      return;
    }

    try {
      if (isObjectEmpty(billingDetailData)) {
        const response = await createCheckoutSession({
          priceId: formData.price.id,
          quantity: +formData.boostsCount,
          locationId: formData.location.value,
          alias: formData.payment.__isNew__ ? formData.payment.value : undefined,
          customer: !formData.payment.__isNew__ ? formData.payment.value : undefined,
        });

        (await stripePromise).redirectToCheckout({ sessionId: response.sessionId });
      } else {
        await updateSubscription({
          subscriptionId: billingDetailData._id,
          priceId: formData.price.id,
          quantity: +formData.boostsCount,
        });
      }
    } catch (err) {}
  };

  const getFee = () => {
    return ((formData.boostsCount * (formData.price?.unit_amount ?? 0)) / 100).toFixed(2);
  };
  const getTax = () => {
    return ((getFee() * taxRates.percentage) / 100).toFixed(2);
  };
  const getTotal = () => {
    return +getFee() + +getTax();
  };

  const onFormDataChange = (name, value) => {
    if (isStringEmpty(value)) {
      setErrorMessage({
        ...errorMessage,
        [name]: `${name} is required.`,
      });

      return;
    }

    setFormData({
      ...formData,
      [name]: value,
    });

    const updatedErrorMessage = { ...errorMessage };
    delete updatedErrorMessage[name];

    setErrorMessage({
      ...updatedErrorMessage,
    });
  };

  const onPriceChange = (event) => {
    const priceId = event.target.value;
    const price = boosts.find((item) => item.id === priceId);

    setFormData({ ...formData, price });

    const updatedErrorMessage = { ...errorMessage };
    delete updatedErrorMessage.price;
    setErrorMessage(updatedErrorMessage);
  };

  const onCustomerChange = (name, customer) => {
    if (!customer) {
      setFormData({
        ...formData,
        payment: null,
      });
      setErrorMessage({
        ...errorMessage,
        payment: 'Payment is required',
      });

      return;
    }

    const { __isNew__, value, label } = customer;

    if (__isNew__) {
      setCurrentBillingAccounts([...currentBillingAccounts, { value, label }]);
    }

    onFormDataChange(name, customer);
  };

  return (
    <Elements stripe={stripePromise}>
      <Modal isOpen={open} onRequestClose={onModalClose} style={customStyles} contentLabel="Edit Billing Details Modal">
        <h2 className="fs-26px fw-600 mt-0">Set up Hourly for your locations!</h2>

        {isLoading ? (
          <Loader />
        ) : (
          <form className="fw-500">
            <CreatableSelectInput
              className="mb-4"
              labelClassName="fs-16px fw-500"
              label="Please select the card"
              placeholder="Add new alias name for card"
              Icon={FaCcStripe}
              options={currentBillingAccounts}
              name="payment"
              value={formData.payment}
              onChange={onCustomerChange}
              errorMessage={errorMessage.payment}
            />

            <SelectInput
              className="mb-4"
              labelClassName="fs-16px fw-500"
              label="Please select the locations you'd like to set up"
              placeholder="Select a location"
              Icon={FaMapMarkerAlt}
              options={props.locations}
              name="location"
              value={formData.location}
              onChange={onFormDataChange}
              errorMessage={errorMessage.location}
              disabled={!isObjectEmpty(billingDetailData)}
            />

            <div className="mb-4">
              <p>How many 'Customer Boosts' would you like a month for this location?</p>

              <span className="fw-600 fs-14px color-windows-blue">{formData.boostsCount}</span>
              <input
                type="range"
                min="10"
                max="20"
                name="boostsCount"
                value={formData.boostsCount}
                onChange={(event) => onFormDataChange('boostsCount', event.target.value)}
                className="d-block w-100 hourly-custom-range"
              />
            </div>

            <div className="mb-4">
              <p>How do you like to pay?</p>
              {boosts.map((price) => {
                let label;
                if (price?.recurring?.interval_count === 1) label = `${price?.recurring?.interval}ly - Rolling`;
                else label = `Every ${price?.recurring?.interval_count} ${price?.recurring?.interval}s`;

                return (
                  <div key={price.id} className="mb-1 fs-14px">
                    <input
                      type="radio"
                      name="paymentType"
                      value={price.id}
                      id="paymentType-monthly"
                      onChange={onPriceChange}
                      checked={formData?.price?.id === price.id}
                    />
                    <label htmlFor="paymentType-monthly" className="ml-2 label-billing">
                      {label}
                    </label>
                  </div>
                );
              })}

              {errorMessage.price && <div className="text-input__info">{errorMessage.price}</div>}
            </div>

            <div className="bg-windows-blue p-3 color-white fw-600">
              <div className="mb-3">
                <p className="fs-14px mt-0 mb-0">Hourly Fee</p>
                <p className="fs-30px mt-2 mb-0">{getFee()} GBP</p>
              </div>

              <div className="mb-3">
                <p className="fs-14px mt-0 mb-0">{`+VAT (${taxRates.percentage}%)`}</p>
                <p className="fs-30px mt-2 mb-0">{getTax()} GBP</p>
              </div>

              <div className="mb-0">
                <p className="fs-14px mt-0 mb-0">Total Payment</p>
                <p className="fs-30px mt-2 mb-0">{getTotal()} GBP</p>
              </div>
            </div>

            <div className="d-flex justify-content-end mt-4 ml-3 mr-3">
              <Button
                onClick={onModalClose}
                title="Cancel"
                className="mr-4 pl-4 pr-4 fs-16px border-windows-blue color-windows-blue"
              />
              <Button
                title={isObjectEmpty(billingDetailData) ? 'Submit' : 'Update'}
                className="pl-4 pr-4 fs-16px bg-windows-blue border-windows-blue color-white"
                onClick={onPaymentUpdate}
                disabled={!isObjectEmpty(errorMessage)}
              />
            </div>
          </form>
        )}
      </Modal>
    </Elements>
  );
}

EditBillingDetailModal.propTypes = {
  open: PropTypes.bool,
  boosts: PropTypes.array,
  isLoading: PropTypes.bool,
  locations: PropTypes.array,
  taxRates: PropTypes.object,
  onModalClose: PropTypes.func,
  billingAccounts: PropTypes.array,
  billingDetailData: PropTypes.object,
};

export default EditBillingDetailModal;
