import React, { useState } from 'react';

import Toast from '../../components/Toast';
import MyLocationForm from '../MyLocation/components/MyLocationForm';

import { handleError } from '../../utils/errorHandler';
import * as locationService from '../../service/location';

const initialFormData = {
  name: '',
  businessHour: {
    startTime: {
      label: '',
      value: '',
    },
    endTime: {
      label: '',
      value: '',
    },
  },
  areaId: {
    label: '',
    value: '',
  },
  categoryId: {
    label: '',
    value: '',
  },
  phone: '',
  email: '',
  facebook: '',
  instagram: '',
  website: '',
};

const AddLocation = (props) => {
  const [errorMessage, setErrorMessage] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState(initialFormData);

  const onClear = () => {
    setFormData(initialFormData);
    setErrorMessage({});
  };

  const onSubmit = async () => {
    setIsSubmitting(true);

    try {
      await locationService.addLocation(formData);
      Toast.success({ title: 'OK', message: 'Location added successfully!' });

      setFormData({ ...initialFormData });
    } catch (err) {
      handleError(err);
    }

    setIsSubmitting(false);
  };

  return (
    <MyLocationForm
      pageTitle="Add new Location"
      isSubmitting={isSubmitting}
      formData={formData}
      errorMessage={errorMessage}
      onClear={onClear}
      onSubmit={onSubmit}
      setFormData={setFormData}
      setErrorMessage={setErrorMessage}
    />
  );
};

export default AddLocation;
