import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Helmet } from 'react-helmet-async';
import React, { useEffect, useRef, useState } from 'react';

import config from '../../../config';
import { FaMapMarkerAlt } from 'react-icons/fa';
import TextInput from '../../../components/TextInput/TextInput';
import mapPlaceholder from '../../../assets/images/map-placeholder.png';

const PlaceSearch = (props) => {
  const { className, value, onChange, errorMessage, title, subtitle } = props;

  const inputRef = useRef(null);
  const [mapUrl, setMapUrl] = useState(null);
  const [isGoogleMapAPILoaded, setIsGoogleMapAPILoaded] = useState(false);

  useEffect(() => {
    setMapUrl(value.googleMapsUrl);
    inputRef.current.value = value.name || '';
  }, [value.googleMapsUrl]);

  useEffect(() => {
    let autocomplete = null;
    window.onLoadGoogleMap = () => {
      setIsGoogleMapAPILoaded(true);

      autocomplete = new window.google.maps.places.Autocomplete(inputRef.current);

      window?.google.maps.event.addListener(autocomplete, 'place_changed', () =>
        onPlaceChange(autocomplete.getPlace())
      );
    };

    return () => {
      delete window.onLoadGoogleMap;
    };
  }, []);

  const onPlaceChange = (place) => {
    const postalCode =
      place.address_components.find((component) => component.types[0] === 'postal_code')?.long_name || '';

    const value = {
      name: place.name || '',
      placeId: place.place_id || '',
      website: place.website || '',
      phone: place.formatted_phone_number || '',
      geolocation: {
        coordinates: [place.geometry.location.lng(), place.geometry.location.lat()],
        type: 'Point',
      },
      googleMapsUrl: place.url,
      address: place.formatted_address || '',
      postalCode,
    };

    onChange(value);
  };

  const googleMapScriptSrc = `https://maps.googleapis.com/maps/api/js?key=${config.googlePlaceApiKey}&callback=onLoadGoogleMap&libraries=places`;

  return (
    <>
      <Helmet>
        <script type="text/javascript" src={googleMapScriptSrc} />
      </Helmet>

      <div
        className={classnames('claim-offer', {
          [className]: className,
        })}
      >
        <div className="claim-offer__title">{title}</div>
        <div className="claim-offer__info mb-3">{subtitle}</div>
        <div className="d-flex w-100 mb-2">
          <TextInput
            className="w-full"
            placeholder="Please search your business location"
            ref={inputRef}
            Icon={FaMapMarkerAlt}
            errorMessage={errorMessage}
            disabled={!isGoogleMapAPILoaded}
          />
        </div>
        {mapUrl ? (
          <iframe className="google-map-viewer" src={`${value.googleMapsUrl}&output=embed`} />
        ) : (
          <img src={mapPlaceholder} className="place-search-map-placeholder" />
        )}
      </div>
    </>
  );
};

PlaceSearch.propTypes = {
  className: PropTypes.string,
  errorMessage: PropTypes.string,
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export default PlaceSearch;
