const routes = {
  ROOT: '/',
  HOME: '/home',
  AUTH: {
    LOGIN: '/login',
    SIGN_UP: '/sign-up',
  },
  USER: {
    EDIT_PROFILE: '/edit-profile',
  },

  DEALS: '/deals',
  CREATE_DEAL: '/deals/create',
  EDIT_DEAL: '/deals/edit/:dealId',

  LOCATION: '/locations',
  ADD_LOCATION: '/locations/create',
  EDIT_LOCATION: '/locations/edit/:locationId',

  SPENDING: '/spending',
  ANALYTICS: '/analytics',
  PAYMENT_SUCCESS: '/payment/success',
  PAYMENT_FAIL: '/payment/fail',
  NOT_FOUND: '/404',
};

export default routes;
