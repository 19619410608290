/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, forwardRef, ChangeEvent } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import classNames from 'classnames';

export const SelectBox = forwardRef(({ options, onChange, value, placeholder = 'Select', multiple = false }, ref) => {
  let defaultValue;

  if (multiple) {
    if (value) {
      defaultValue = value;
    } else {
      defaultValue = [{ label: placeholder, value: undefined }];
    }
  } else {
    if (value) {
      defaultValue = value;
    } else {
      defaultValue = { label: placeholder, value: undefined };
    }
  }

  const [selectedOption, setSelectedOption] = useState(
    // multiple ? [value ? value : options[0]] : value ? value : options[0]
    defaultValue
  );

  const onChangeHandler = (newValue) => {
    console.log('---- on change', newValue);
    if (multiple && newValue.length > 1 && newValue[0].value === undefined) {
      onChange(newValue.slice(1));
      setSelectedOption(newValue.slice(1));

      return;
    }

    if (multiple && newValue.length === 0) {
      onChange([{ label: placeholder, value: undefined }]);
      setSelectedOption([{ label: placeholder, value: undefined }]);
      return;
    }

    console.log('-e-w-ew-e-e-e-w-ew-ewe-we-----');
    onChange(newValue);
    setSelectedOption(newValue);
  };

  const renderLabel = () => {
    if (selectedOption) {
      if (multiple) {
        return selectedOption.map((option) => option.label).join(', ');
      }
      return selectedOption.label;
    }

    return '';
  };

  return (
    <Listbox value={selectedOption} onChange={onChangeHandler} multiple={multiple}>
      {({ open }) => (
        <>
          <div className="mt-1 relative">
            <Listbox.Button className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
              <span className="block truncate">{renderLabel()}</span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                {options.map((option) => (
                  <Listbox.Option
                    key={option.label}
                    className={({ active }) =>
                      classNames(
                        active ? 'text-white bg-indigo-600' : 'text-gray-900',
                        'cursor-default select-none relative py-2 pl-3 pr-9'
                      )
                    }
                    value={option}
                  >
                    {({ selected, active, ...r }) => {
                      const isSelectedWithMultiple = multiple && selectedOption.some((so) => so.value === option.value);

                      return (
                        <>
                          <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                            {option.label}
                          </span>

                          {selected || isSelectedWithMultiple ? (
                            <span
                              className={classNames(
                                active ? 'text-white' : 'text-indigo-600',
                                'absolute inset-y-0 right-0 flex items-center pr-4'
                              )}
                            >
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      );
                    }}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
});
