import http from '../utils/http';
import * as endpoints from '../constants/endpoints';
import { interpolate } from 'utils/string';

export const updateSubscription = async ({ subscriptionId, priceId, quantity }) => {
  const url = interpolate(endpoints.SUBSCRIPTION_DETAILS, { id: subscriptionId });

  const response = await http.patch(url, {
    body: { priceId, quantity },
  });

  return response.data;
};

export const cancelSubscription = async (subscriptionId) => {
  const url = interpolate(endpoints.SUBSCRIPTION_DETAILS, { id: subscriptionId });

  const response = await http.delete(url);

  return response.data;
};
