import http from '../../utils/http';
import * as endpoints from '../../constants/endpoints';
import { GET_LOCATION_LIST, GET_LOCATION_LIST_FAIL, GET_LOCATION_LIST_SUCCESS } from '../types';

export const getLocationList = () => {
  return function (dispatch) {
    dispatch({ type: GET_LOCATION_LIST });

    return http
      .get(endpoints.LOCATION)
      .then((res) => dispatch(saveLocations(res.data)))
      .catch((err) => dispatch(errorOnFetchLocations(err)));
  };
};

const saveLocations = (payload) => ({
  type: GET_LOCATION_LIST_SUCCESS,
  payload,
});

const errorOnFetchLocations = (payload) => ({
  type: GET_LOCATION_LIST_FAIL,
  payload,
});
