import { GET_BOOST_INFO, GET_BOOST_INFO_FAIL, GET_BOOST_INFO_SUCCESS } from 'redux/types';
import { getBoostInfoList } from 'service/boost';

export const getBoostsInfo = () => {
  return (dispatch) => {
    const onGetBoostsInfoSuccess = (response) => {
      const { billingAccounts, boosts, taxRate } = response;

      const prices = boosts?.data ?? [];
      const filteredPrice = prices.filter((item) => !!item.recurring && !!item.active);

      dispatch({
        type: GET_BOOST_INFO_SUCCESS,
        payload: {
          taxRate,
          billingAccounts,
          prices: filteredPrice,
        },
      });
    };

    const onGetBoostsInfoError = (error) => dispatch({ type: GET_BOOST_INFO_FAIL, payload: error });

    dispatch({
      type: GET_BOOST_INFO,
    });

    return getBoostInfoList().then(onGetBoostsInfoSuccess).catch(onGetBoostsInfoError);
  };
};
