import React from 'react';
import PropTypes from 'prop-types';

const ButtonLoader = (props) => (
  <div className={props.className || 'btn-loader-light'}>
    <div />
    <div />
    <div />
    <div />
  </div>
);

ButtonLoader.propTypes = {
  className: PropTypes.string,
};

export default ButtonLoader;
