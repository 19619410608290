import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { FaTag, FaMapMarkerAlt, FaHeadset, FaPhone, FaCalendarAlt, FaClock } from 'react-icons/fa';

import ClaimDeal from '../ClaimDeal/ClaimDeal';
import DealImgUpload from '../DealImgUpload/DealImgUpload';

import Button from '../../../../components/Button/Button';
import Loader from '../../../../components/Loader/Loader';
import TextInput from '../../../../components/TextInput/TextInput';
import PageTitle from '../../../../components/PageTitle/PageTitle';
import SupportLink from '../../../../components/SupportLink/SupportLink';
import SelectInput from '../../../../components/SelectInput/SelectInput';

import { isEmpty as isObjectEmpty } from 'utils/object';
import { addDataToForm, isFormValid } from 'utils/formValidation';

import { DAYS } from 'constants/appConstant';
import { timeArrayOptions } from 'constants/timeArray';

function MyDealsForm(props) {
  const {
    pageTitle = '',
    formData = {},
    setFormData,
    errorMessage = {},
    setErrorMessage,
    onClear,
    onSubmit,
    locations,
    isSubmitting,
    isGettingData,
    getLocationList,
    isLoadingLocation,
  } = props;

  useEffect(() => {
    getLocationList();
  }, []);

  const handleOnSubmit = () => {
    if (!isFormValidated()) {
      return;
    }

    onSubmit();
  };

  const isFormValidated = () => {
    const requiredFields = {
      title: 'Name',
      days: 'Days',
      locationIds: 'Location',
      'timeSlot.startTime': 'Start Time',
      'timeSlot.endTime': 'End Time',
      'claimCodes.passphase': 'Pass Phrase',
    };

    let { isValid, validationMessage } = isFormValid(formData, requiredFields);

    if (!formData?.image?.[0]?.data_url) {
      isValid = false;

      validationMessage = {
        ...validationMessage,
        image: 'Image is required',
      };
    }

    setErrorMessage(validationMessage);

    return isValid;
  };

  const onTextInputChange = (name, value, splitName = false) => {
    const updatedFormData = addDataToForm(formData, name, value, splitName);

    setErrorMessage({});
    setFormData(updatedFormData);
  };

  if (isLoadingLocation || isSubmitting || isGettingData) {
    return <Loader />;
  }

  console.log('---f-f-dsf-d', formData);

  return (
    <div className="w-100">
      <div className="d-flex justify-content-between mb-4">
        <PageTitle title={pageTitle} />
      </div>

      <div className="row no-gutters mb-4">
        <div className="col-sm-12 col-md-12 col-lg-6 pr-4 pb-4 no-gutters">
          <div className="row col-12 no-gutters">
            <div className="col-6 pr-4">
              <TextInput
                className="mb-4"
                label="Name of the offer:"
                placeholder="1/2 Price Drinks"
                Icon={FaTag}
                name="title"
                value={formData.title}
                onChange={onTextInputChange}
                errorMessage={errorMessage.title}
              />
              <SelectInput
                isMulti
                className="mb-4"
                label="Select a location:"
                placeholder="Select a location"
                Icon={FaMapMarkerAlt}
                name="locationIds"
                options={locations}
                value={formData.locationIds}
                onChange={onTextInputChange}
                errorMessage={errorMessage.locationIds}
              />
            </div>

            <div className="col-6 ">
              <SelectInput
                isMulti
                className="mb-4"
                label="Select days:"
                placeholder="Select days"
                Icon={FaCalendarAlt}
                name="days"
                options={DAYS}
                value={formData.days}
                onChange={onTextInputChange}
                errorMessage={errorMessage.days}
              />

              <div className="row mb-4 align-items-end">
                <SelectInput
                  className="col-6"
                  label="Timeslot:"
                  placeholder="Start Time"
                  Icon={FaClock}
                  name="timeSlot.startTime"
                  options={timeArrayOptions}
                  value={formData.timeSlot.startTime}
                  onChange={(name, value) => onTextInputChange(name, value, true)}
                  errorMessage={errorMessage['timeSlot.startTime']}
                />

                <SelectInput
                  className="col-6"
                  placeholder="End Time"
                  Icon={FaClock}
                  name="timeSlot.endTime"
                  options={timeArrayOptions}
                  value={formData.timeSlot.endTime}
                  onChange={(name, value) => onTextInputChange(name, value, true)}
                  errorMessage={errorMessage['timeSlot.endTime']}
                />
              </div>
            </div>
          </div>

          <ClaimDeal
            className="mb-4"
            name="claimCodes.passphase"
            value={formData.claimCodes.passphase}
            onChange={(name, value) => onTextInputChange(name, value, true)}
            errorMessage={errorMessage['claimCodes.passphase']}
          />
        </div>

        <div className="col-sm-12 col-md-12 col-lg-6">
          <DealImgUpload
            image={formData?.image}
            errorMessage={errorMessage.image}
            onImageChange={(file) => onTextInputChange('image', file)}
          />
        </div>
      </div>

      <div className="d-flex justify-content-end mt-1 mb-5">
        <Button onClick={onClear} title="CLEAR" className="topup-btn pl-5 pr-5 mr-5" />
        <Button
          title="SUBMIT"
          className="submit-btn pl-5 pr-5"
          disabled={!isObjectEmpty(errorMessage)}
          onClick={handleOnSubmit}
        />
      </div>

      <div className="row no-gutters">
        <div className="mt-2 col-12">
          <div className="d-flex justify-content-end">
            <SupportLink title="CONTACT" link="#" className="mr-4" Icon={FaPhone} />
            <SupportLink title="SUPPORT" link="#" className="mr-4" Icon={FaHeadset} />
          </div>
        </div>
      </div>
    </div>
  );
}

MyDealsForm.propTypes = {
  errorMessage: PropTypes.object,
  formData: PropTypes.object,
  getLocationList: PropTypes.func,
  isGettingData: PropTypes.any,
  isLoadingLocation: PropTypes.any,
  isSubmitting: PropTypes.any,
  locations: PropTypes.any,
  onClear: PropTypes.any,
  onSubmit: PropTypes.func,
  pageTitle: PropTypes.string,
  setErrorMessage: PropTypes.func,
  setFormData: PropTypes.func,
};

export default MyDealsForm;
