import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import React, { useState, useEffect } from 'react';

import Toast from 'components/Toast';
import MyDealsForm from 'screens/MyDeals/components/MyDealsForm';

import ROUTES from 'constants/routes';

import { handleError } from 'utils/errorHandler';
import * as dealService from '../../service/deal';
import { formatDealAPIDataForForm } from 'screens/MyDeals/components/dealUtil';

const initialFormData = {
  title: '',
  days: [
    {
      label: '',
      value: '',
    },
  ],
  locationIds: [
    {
      label: '',
      value: '',
    },
  ],
  timeSlot: {
    startTime: {
      label: '',
      value: '',
    },
    endTime: {
      label: '',
      value: '',
    },
  },
  claimCodes: {
    passphase: '',
  },
  image: [],
};

const EditDeal = (props) => {
  const history = useHistory();

  const dealId = props?.match?.params?.dealId;
  const [errorMessage, setErrorMessage] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isGettingData, setIsGettingData] = useState(false);
  const [formData, setFormData] = useState(initialFormData);

  useEffect(() => {
    const getDeal = async () => {
      setIsGettingData(true);

      try {
        const dealData = await dealService.getDeal(dealId);
        const formattedData = formatDealAPIDataForForm(dealData);

        setFormData(formattedData);
      } catch (error) {
        handleError(error);
      }

      setIsGettingData(false);
    };

    getDeal();
  }, [dealId]);

  const onClear = () => {
    setFormData(initialFormData);
    setErrorMessage({});
  };

  const onSubmit = async () => {
    setIsSubmitting(true);

    try {
      await dealService.updateDeal(dealId, formData);
      Toast.success({ title: 'OK', message: 'Deal Edited successfully!' });

      history.push(ROUTES.DEALS);
    } catch (error) {
      handleError(error);
    }

    setIsSubmitting(false);
  };

  return (
    <MyDealsForm
      pageTitle="Edit Deals"
      isSubmitting={isSubmitting}
      isGettingData={isGettingData}
      formData={formData}
      errorMessage={errorMessage}
      onClear={onClear}
      onSubmit={onSubmit}
      setFormData={setFormData}
      setErrorMessage={setErrorMessage}
    />
  );
};

EditDeal.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      dealId: PropTypes.any,
    }),
  }),
};

export default EditDeal;
