export const LOGIN_CUSTOMER = '/v1/company/auth/login';
export const SIGNUP_CUSTOMER = '/v1/company/auth/register';
export const REFRESH_ACCESS_TOKEN = '/v1/company/auth/refresh-access-token';

export const UPDATE_USER = '/v1/company/customers/:userId';
export const FETCH_CURRENT_USER = '/v1/company/customers/me';

export const LOCATION = '/v1/company/locations';
export const LOCATION_SINGLE = '/v1/company/locations/:locationId';

export const DEAL = '/v1/company/deals';
export const DEAL_SINGLE = '/v1/company/deals/:dealId';

export const AREA = '/v1/company/areas';
export const CATEGORY = '/v1/company/categories';

export const FILE_UPLOAD = '/v1/company/file/upload';
export const BOOST_PRICE = '/v1/company/payment/boost-price';
export const CHECKOUT_SESSION = '/v1/company/payment/checkout-session';

export const SUBSCRIPTION = '/v1/company/subscription';
export const SUBSCRIPTION_DETAILS = '/v1/company/subscription/:id';
