import * as endpoints from '../constants/endpoints';

import http from '../utils/http';
import { interpolate } from 'utils/string';
import { formatDealDataForAPI } from 'screens/MyDeals/components/dealUtil';

/**
 * Get a Single Deal Detail.
 *
 * @param {string} dealId
 *
 * @returns {object}
 */
export const getDeal = async (dealId) => {
  const url = interpolate(endpoints.DEAL_SINGLE, { dealId });
  const { data } = await http.get(url);

  return data;
};

export const addDeal = async (deal) => {
  const formattedDeal = formatDealDataForAPI(deal);

  const response = await http.post(endpoints.DEAL, {
    body: formattedDeal,
    headers: { 'content-type': 'multipart/form-data' },
  });

  return response.data;
};

/**
 * Update Deal.
 *
 * @param {string} dealId
 * @param {object} body
 *
 * @returns {object}
 */
export async function updateDeal(dealId, body) {
  const formattedDeal = formatDealDataForAPI(body);

  const url = interpolate(endpoints.DEAL_SINGLE, { dealId });
  const { data } = await http.patch(url, {
    body: formattedDeal,
    headers: { 'content-type': 'multipart/form-data' },
  });

  return data;
}
