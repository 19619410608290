import { connect } from 'react-redux';

import { getAreaList } from '../../../../redux/actions/area';
import { getAreaOptions } from '../../../../redux/selectors/area';
import { getCategoryList } from '../../../../redux/actions/category';
import { getCategoryOptions } from '../../../../redux/selectors/category';

// import Component from './MyLocationForm';
import Component from './OldForm';

const mapStateToProps = (state, props) => {
  return {
    isLoadingCategory: state.category.isLoading,
    isLoadingArea: state.area.isLoading,
    areas: getAreaOptions(state),
    categories: getCategoryOptions(state),
  };
};

const mapDispatchToProps = { getCategoryList, getAreaList };

export default connect(mapStateToProps, mapDispatchToProps)(Component);
