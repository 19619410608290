import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'react-modal';

import Button from 'components/Button/Button';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '12px',
    padding: '30px 35px',
    overflowY: 'auto',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
};

const CancelSubscriptionModal = (props) => {
  const { open = false, billingDetailData, onSuccess = () => {}, onModalClose = () => {} } = props;

  return (
    <Modal isOpen={open} onRequestClose={onModalClose} style={customStyles} contentLabel="Edit Billing Details Modal">
      <h2 className="fs-26px fw-600 mt-0">Cancel Subscription!</h2>
      <div>{`Are you sure you want to cancel subscription for ${billingDetailData?.location?.name || '-'}?`}</div>
      <div className="d-flex justify-content-end mt-4 ml-3 mr-3">
        <Button
          onClick={onModalClose}
          title="No"
          className="mr-4 pl-4 pr-4 fs-16px border-windows-blue color-windows-blue"
        />
        <Button
          title="Yes"
          className="pl-4 pr-4 fs-16px bg-windows-blue border-windows-blue color-white"
          onClick={onSuccess}
        />
      </div>
    </Modal>
  );
};

CancelSubscriptionModal.propTypes = {
  open: PropTypes.bool,
  onSuccess: PropTypes.func,
  onModalClose: PropTypes.func,
  billingDetailData: PropTypes.object,
};
export default CancelSubscriptionModal;
