import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import React, { useState, useEffect } from 'react';

import Toast from '../../components/Toast';
import MyLocationForm from '../MyLocation/components/MyLocationForm';

import ROUTES from 'constants/routes';

import { handleError } from '../../utils/errorHandler';
import * as locationService from '../../service/location';
import { formatLocationAPIDataForForm } from 'screens/MyLocation/components/LocationUtil';

const initialFormData = {
  name: '',
  businessHour: {
    startTime: {
      label: '',
      value: '',
    },
    endTime: {
      label: '',
      value: '',
    },
  },
  areaId: {
    label: '',
    value: '',
  },
  categoryId: {
    label: '',
    value: '',
  },
  phone: '',
  email: '',
  facebook: '',
  instagram: '',
  website: '',
};

const EditLocation = (props) => {
  const history = useHistory();

  const locationId = props?.match?.params?.locationId;
  const [errorMessage, setErrorMessage] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isGettingData, setIsGettingData] = useState(false);
  const [formData, setFormData] = useState(initialFormData);

  useEffect(() => {
    const getDeal = async () => {
      setIsGettingData(true);

      try {
        const locationData = await locationService.getLocation(locationId);
        const formattedData = formatLocationAPIDataForForm(locationData);

        setFormData(formattedData);
      } catch (error) {
        handleError(error);
      }

      setIsGettingData(false);
    };

    getDeal();
  }, [locationId]);

  const onClear = () => {
    setFormData(initialFormData);
    setErrorMessage({});
  };

  const onSubmit = async () => {
    setIsSubmitting(true);

    try {
      await locationService.updateLocationData(locationId, formData);
      Toast.success({ title: 'OK', message: 'Location Edited successfully!' });

      history.push(ROUTES.LOCATION);
    } catch (err) {
      handleError(err);
    }

    setIsSubmitting(false);
  };

  return (
    <MyLocationForm
      pageTitle="Edit Location"
      isSubmitting={isSubmitting}
      isGettingData={isGettingData}
      formData={formData}
      errorMessage={errorMessage}
      onClear={onClear}
      onSubmit={onSubmit}
      setFormData={setFormData}
      setErrorMessage={setErrorMessage}
    />
  );
};

EditLocation.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      locationId: PropTypes.any,
    }),
  }),
};

export default EditLocation;
