import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ImageUploading from 'react-images-uploading';

import ImageCropModal from 'components/ImageCropModal/ImageCropModal';
import imagePlaceholder from 'assets/images/placeholder.png';

const DealImgUpload = (props) => {
  const { image, onImageChange, errorMessage } = props;

  const [uploadedImage, setUploadedImage] = useState();
  const [showImageCropModal, setShowImageCropModal] = useState(false);

  const onChange = (file) => {
    const image = file?.[0]?.data_url;

    setUploadedImage(image);
    setShowImageCropModal(true);
  };

  return (
    <>
      <ImageUploading value={image} onChange={onChange} dataURLKey="data_url">
        {({ imageList, onImageUpload, onImageUpdate, isDragging, dragProps }) => {
          const avatarUrl = imageList?.[0]?.data_url || imagePlaceholder;
          const isImageChosen = imageList?.length > 0;
          const handleClick = () => {
            if (isImageChosen) {
              onImageUpdate(0);
            } else {
              onImageUpload();
            }
          };

          return (
            <div className={`wrapper mb-4 ${isDragging ? 'layer' : ''}`} onClick={handleClick} {...dragProps}>
              <div className="p-2">
                {avatarUrl ? (
                  <img src={avatarUrl} alt="" />
                ) : (
                  <div className="upload__view">
                    <button>BROWSE...</button>
                  </div>
                )}
              </div>

              <div className="fs-14px fw-400 p-2 upload__info">
                {errorMessage ? (
                  <p className="color-red-fire m-0">
                    {errorMessage} &nbsp;<span className="color-blue-light fw-500">Try Again</span>
                  </p>
                ) : (
                  <p className="color-black m-0">Attach files by dragging & dropping, selecting or pasting them.</p>
                )}
              </div>
            </div>
          );
        }}
      </ImageUploading>

      {showImageCropModal && (
        <ImageCropModal
          open={showImageCropModal}
          onModalClose={() => setShowImageCropModal(false)}
          image={uploadedImage}
          onImageCropComplete={onImageChange}
        />
      )}
    </>
  );
};

DealImgUpload.propTypes = {
  image: PropTypes.array,
  errorMessage: PropTypes.string,
  onImageChange: PropTypes.func.isRequired,
};

export default DealImgUpload;
