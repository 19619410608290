export const formatLocationAPIDataForForm = (locationData) => {
  const {
    name,
    googleMapsUrl,
    businessHour = {},
    contacts,
    areaId,
    categoryId,
    social,
    addressLine1,
    ...rest
  } = locationData;
  const { phone, email } = contacts;
  const { facebook, instagram, website } = social;

  const formattedData = {
    ...rest,
    name,
    googleMapsUrl,
    areaId: {
      label: '',
      value: areaId,
    },
    categoryId: {
      label: '',
      value: categoryId,
    },
    phone,
    email,
    facebook,
    instagram,
    website,
    addressLine1,
    businessHour: {
      startTime: {
        label: `${String(businessHour?.startTime).padStart(2, '0')}:00`,
        value: businessHour?.startTime,
      },
      endTime: {
        label: `${String(businessHour?.endTime).padStart(2, '0')}:00`,
        value: businessHour?.endTime,
      },
    },
  };

  return formattedData;
};

export const formatLocationDataForAPI = (location) => {
  const {
    facebook,
    name,
    areaId,
    categoryId,
    instagram,
    businessHour,
    website,
    phone,
    email,
    placeId,
    geolocation,
    addressLine1,
    googleMapsUrl,
  } = location;

  const formattedlocation = {
    name,
    areaId: areaId.value,
    categoryId: categoryId.value,
    businessHour: {
      startTime: businessHour?.startTime?.value,
      endTime: businessHour?.endTime?.value,
    },
    social: {
      facebook,
      instagram,
      website,
    },
    contacts: {
      phone,
      email,
    },
    placeId,
    geolocation,
    addressLine1,
    googleMapsUrl,
  };

  return formattedlocation;
};
