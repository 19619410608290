import { capitalize } from 'utils/string';

export const formatDealAPIDataForForm = (dealData) => {
  const { title, days, locationIds, timeSlot = {}, claimCodes, imageUrl } = dealData;

  const formattedDate =
    days?.map((day) => {
      const capitalizedDay = capitalize(day);

      return {
        label: capitalizedDay,
        value: capitalizedDay,
      };
    }) || [];

  const image = [];

  if (imageUrl) {
    const imageData = {
      data_url: imageUrl,
      file: null,
    };

    image.push(imageData);
  }

  const formattedDeal = {
    title,
    claimCodes,
    image,
    days: formattedDate,
    locationIds: locationIds?.map((locationid) => ({
      label: '',
      value: locationid,
    })),
    timeSlot: {
      startTime: {
        label: '',
        value: timeSlot?.startTime,
      },
      endTime: {
        label: '',
        value: timeSlot?.endTime,
      },
    },
  };

  return formattedDeal;
};

export const formatDealDataForAPI = (data) => {
  const { image, timeSlot, days, locationIds } = data;

  const file = image?.[0]?.file;
  const formattedData = {
    ...data,
    days: days.map((item) => item.value),
    locationIds: locationIds.map((item) => item.value),
    timeSlot: {
      startTime: timeSlot?.startTime?.value,
      endTime: timeSlot?.endTime?.value,
    },
  };

  if (!file) {
    formattedData.imageUrl = image?.[0]?.data_url;
  }

  if (formattedData?.image) {
    delete formattedData.image;
  }

  const form = new FormData();
  const formData = JSON.stringify(formattedData);

  form.append('file', file);
  form.append('formData', formData);

  return form;
};
